import { combineReducers } from "redux";

import system from "./system.reducer";
import viewData from "./viewData.reducer";

const appReducer = combineReducers({
  system,
  viewData
});

export default appReducer;

import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import InternalPrivateRoute from "./components/InternalPrivateRoute";
import { IDefaultRootState } from "./interfaces";
import { validateAuthDataAction } from "./actions";

// Layout
import AuthLayout from "./layouts/AuthLayout";

// Auth Routes
import DashUserSignIn from "./pages/dashUserSignin";
import PageNotFound from "./pages/pageNotFound";

// Statistics Routes
import Users from "./pages/users";
import Conversations from "./pages/conversations";
import UserActivities from "./pages/userActivities";
import AlertDetails from "./pages/alertDetails";

// Analytics Routes
import Stocks from "./pages/stocks";

// Metrics Routes
import Dashboard from "./pages/dashboard";
import TradeMetric from "./pages/tradeMetric";
import PriceAlertMetric from "./pages/priceAlertMetric";
import CurrentStockPrice from "./pages/currentStockPrice";
import MarketInsightMetric from "./pages/marketInsightMetric";
import OtherMetrics from "./pages/otherMetrics";

// Internal Routes
import PnLRankings from "./pages/pnLRankings";
import StockProfitabilities from "./pages/stockProfitabilities";
import DashUsers from "./pages/dashUsers";
import DashUserRegister from "./pages/dashUserRegister";

function App() {
  const dispatch = useDispatch();

  const system = useSelector((state: IDefaultRootState) => state.system);
  const { isAuthenticated, dashUserProfile } = system;

  useEffect(() => {
    dispatch(validateAuthDataAction());
  }, [dispatch]);

  return (
    // REF: https://stackoverflow.com/a/66289280/3269542
    <BrowserRouter>
      <Switch>
        <InternalPrivateRoute
          isAuthenticated={isAuthenticated}
          dashUserProfile={dashUserProfile}
          path="/pnl-rankings"
          component={PnLRankings}
          exact
        />
        <InternalPrivateRoute
          isAuthenticated={isAuthenticated}
          dashUserProfile={dashUserProfile}
          path="/stock-profitabilities"
          component={StockProfitabilities}
          exact
        />
        <InternalPrivateRoute
          isAuthenticated={isAuthenticated}
          dashUserProfile={dashUserProfile}
          path="/dash-users/register"
          component={DashUserRegister}
          exact
        />
        <InternalPrivateRoute
          isAuthenticated={isAuthenticated}
          dashUserProfile={dashUserProfile}
          path="/dash-users"
          component={DashUsers}
          exact
        />

        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/users"
          component={Users}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/conversations"
          component={Conversations}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/user-activities"
          component={UserActivities}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/activities-details/:userId"
          component={AlertDetails}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/stocks"
          component={Stocks}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/trade"
          component={TradeMetric}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/price-alert"
          component={PriceAlertMetric}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/current-stock-price"
          component={CurrentStockPrice}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/market-insight"
          component={MarketInsightMetric}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/others"
          component={OtherMetrics}
        />
        <AuthenticatedRoute
          isAuthenticated={isAuthenticated}
          profile={dashUserProfile}
          path="/"
          exact
          component={Dashboard}
        />

        <AuthLayout>
          <Switch>
            <Route exact path="/signin" component={DashUserSignIn} />
            <Route exact path="/page-not-found" component={PageNotFound} />
            <Route component={PageNotFound} />
          </Switch>
        </AuthLayout>
      </Switch>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles, WithStyles } from "@material-ui/core/styles";

import { fetchUsers } from "../../asyncActions";
import { UserTable } from "../../components/UserTable";
import { systemUpdateCurrentPath } from "../../actions";
import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
import { Loading } from "../../components/Loading";
import { statisticPagestyles } from "../../layouts/StatisticPage.style";

interface UsersProps extends WithStyles<typeof statisticPagestyles> {
  viewData: any;
  system: any;
}

function Users(props: UsersProps) {
  const dispatch = useDispatch();
  const mainLayoutClasses = mainLayoutStyles();

  const { system, viewData, classes } = props;

  const { isLoading } = viewData;
  const { users } = viewData;
  const { accessibleApis } = system;
  const { usersEndpoint } = accessibleApis;

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsers(usersEndpoint));
  }, [dispatch, usersEndpoint]);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="User Statuses" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Paper className={classes.paper}>
          <div className={classes.contentWrapper}>
            {isLoading && <Loading />}
            {!isLoading && _.isEmpty(users) && (
              <Typography color="textSecondary" align="center">
                No Data
              </Typography>
            )}
            {!isLoading && !_.isEmpty(users) && <UserTable data={users} />}
          </div>
        </Paper>
      </main>
    </div>
  );
}

export default withStyles(statisticPagestyles)(Users);

import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter
} from "react-table";
import moment from "moment";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
import { systemUpdateCurrentPath } from "../../actions";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { CSVLink } from "react-csv";
import SearchIcon from "@material-ui/icons/Search";
import {
  AppBar,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";

import { IDefaultRootState } from "../../interfaces";
import { RTDefaultColumnFilter, RTGlobalFilter } from "../../components/TableElements";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { fetchStockProfitabilities } from "../../asyncActions";
import Header from "../../components/Header";
import { stockProfitabilityTableColumns } from "../../components/StockProfitabilitiesTable/StockProfitabilityTableColumns";
import { stockProfitabilitiesTableStyles } from "../../components/StockProfitabilitiesTable/StockProfitabilitiesTable.style";
import { statisticPagestyles } from "../../layouts/StatisticPage.style";
import { getTimestamptString } from "../../utils";

interface UsersProps extends WithStyles<typeof statisticPagestyles> {
  viewData: any;
  system: any;
}

function StockProfitabilities(props: UsersProps) {
  const dispatch = useDispatch();
  const { classes } = props;
  const tableClasses = stockProfitabilitiesTableStyles();
  const mainLayoutClasses = mainLayoutStyles();

  const system = useSelector((state: IDefaultRootState) => state.system);
  const { accessibleApis } = system;
  const { profitableHoldingsEndpoint } = accessibleApis;
  const viewData = useSelector((state: IDefaultRootState) => state.viewData);
  const { stockProfitability } = viewData;

  const [isInitialRender, setisInitialRender] = useState(true);

  const defaultStartDate: Date = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate: Date = new Date();
  const defaultStartDateString = moment
    .tz(defaultStartDate, "Asia/Hong_Kong")
    .startOf("day")
    .toISOString();
  const defaultEndDateString = moment
    .tz(defaultEndDate, "Asia/Hong_Kong")
    .endOf("day")
    .toISOString();

  const [selectedStartDate, setselectedStartDate] =
    useState<string>(defaultStartDateString);
  const [selectedEndDate, setselectedEndDate] = useState<string>(defaultEndDateString);

  const handleSearch = () => {
    try {
      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchStockProfitabilities(
          profitableHoldingsEndpoint,
          selectedStartDate,
          selectedEndDate
        )
      );
    } catch (error) {}
  };

  const handlePickerOnChange = (rangeSelection: any) => {
    try {
      const startDate: Date = rangeSelection.startDate;
      const endDate: Date = rangeSelection.endDate;

      const startDateString = moment
        .tz(startDate, "Asia/Hong_Kong")
        .startOf("day")
        .toISOString();
      const endDateString = moment
        .tz(endDate, "Asia/Hong_Kong")
        .endOf("day")
        .toISOString();
      setselectedStartDate(startDateString);
      setselectedEndDate(endDateString);
    } catch (error) {
      console.error("[Error] Internal Dashboard handlePickerOnChange");
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);

      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchStockProfitabilities(
          profitableHoldingsEndpoint,
          selectedStartDate,
          selectedEndDate
        )
      );
    }
  }, [
    dispatch,
    isInitialRender,
    selectedStartDate,
    selectedEndDate,
    profitableHoldingsEndpoint
  ]);

  const defaultColumn = useMemo(
    () => ({
      Filter: RTDefaultColumnFilter
    }),
    []
  );

  const filterTypes = useMemo(
    () => ({
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter, pageIndex, pageSize }
  } = useTable(
    {
      columns: stockProfitabilityTableColumns,
      data: stockProfitability,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const CSVColumnHeaders = stockProfitabilityTableColumns.map((u) => {
    return { label: u.Header, key: u.accessor };
  });

  const CSVDateHandler = (value: any) => {
    const data = value.map((item: any) => {
      return {
        ...item,
        inserted: getTimestamptString(item.inserted, "DD/MM/YYYY hh:mm A")
      };
    });
    return data;
  };

  const handleCellRendering = (cell: any, row: any) => {
    try {
      switch (cell.column.id) {
        case "quantity":
          return <>{Number(cell.value).toLocaleString()}</>;
        case "cost_price":
          return (
            <>
              {Number(cell.value).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          );
        case "last_price":
          return (
            <>
              {Number(cell.value).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          );
        case "chng_pct":
          return (
            <>
              {Number(cell.value).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          );
        case "absolute_pnl_hkd":
          return (
            <>
              {Number(cell.value).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          );
        case "absolute_pnl":
          return (
            <>
              {Number(cell.value).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          );
        case "inserted":
          return <>{getTimestamptString(cell.value, "DD/MM/YYYY hh:mm A")}</>;
        default:
          return <>{cell.render("Cell")}</>;
      }
    } catch (error) {
      console.error("[Error] User Table handleCellRendering");
      return <>{cell.render("Cell")}</>;
    }
  };

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Stock Profitabilities" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Paper className={classes.paper}>
          <Toolbar>
            <Grid container className={classes.dateBar}>
              <Grid item className={classes.dateGrid}>
                <CustomDateRangePicker handleOnChange={handlePickerOnChange} />
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleSearch}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          <AppBar position="static" color="default" elevation={0}>
            <Toolbar>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                  <RTGlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </Grid>
                <Grid item>
                  <CSVLink
                    filename={"internal_stock_profitability.csv"}
                    data={CSVDateHandler(stockProfitability)}
                    headers={CSVColumnHeaders}
                  >
                    Download CSV
                  </CSVLink>
                </Grid>
              </Grid>
            </Toolbar>
            <Toolbar>
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                  <Select
                    labelId="rows-per-page-select-label"
                    id="rows-per-page-select"
                    style={{
                      fontSize: "14px",
                      border: "0px",
                      margin: "0px 20px 0px 0px"
                    }}
                    value={pageSize}
                    disableUnderline={true}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <MenuItem key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </MenuItem>
                    ))}
                  </Select>
                  <span style={{ fontSize: "14px", margin: "0px 20px 0px 0px" }}>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {<FirstPageIcon />}
                  </IconButton>
                  <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
                    <KeyboardArrowRight />
                  </IconButton>
                  <IconButton
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    <LastPageIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <TableContainer className={tableClasses.container}>
            <Table
              className={tableClasses.table}
              stickyHeader
              aria-label="sticky table"
              {...getTableProps()}
            >
              <TableHead>
                {headerGroups.map((headerGroup: any) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowUpIcon />
                            )
                          ) : (
                            <UnfoldMoreIcon />
                          )}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row: any, i: number) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {handleCellRendering(cell, row)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </main>
    </div>
  );
}

export default withStyles(statisticPagestyles)(StockProfitabilities);

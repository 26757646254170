import React from "react";
import { useAsyncDebounce } from "react-table";
import TextField from "@material-ui/core/TextField";

export function RTGlobalFilter(props: any) {
  // { preGlobalFilteredRows, globalFilter, setGlobalFilter}
  const count = props.preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(props.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    props.setGlobalFilter(value || undefined);
  }, 200);

  return (
    <TextField
      fullWidth
      style={{
        fontSize: "14px",
        border: "0"
      }}
      placeholder={`Search all in ${count} records...`}
      InputProps={{
        disableUnderline: true
        // className: classes.searchInput
      }}
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
}

export const stockProfitabilityTableColumns = [
  {
    accessor: "userid",
    Header: "User ID",
    minWidth: 100,
    align: "left"
  },
  {
    accessor: "inserted",
    Header: "Date Inserted",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "ranking",
    Header: "Ranking",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "currency",
    Header: "Currency",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "full_name",
    Header: "Full Name",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "symbol",
    Header: "Ticker",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "quantity",
    Header: "Quantity",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "cost_price",
    Header: "Cost Price",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "last_price",
    Header: "Last Price",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "chng_pct",
    Header: "Percentage Change",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "absolute_pnl_hkd",
    Header: "Unrealized P&L (HKD)",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "absolute_pnl",
    Header: "Unrealized P&L (Trading Ccy)",
    minWidth: 170,
    align: "left"
  }
];

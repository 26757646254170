import { makeStyles } from "@material-ui/core/styles";

export const cumulativeTableStyles = makeStyles({
  table: {
    margin: "0px",
    padding: "0px"
  },
  tableHeader: {
    backgroundColor: "#f4f5f7"
  },
  tooltipMessage: {
    margin: "0px 0px 0px 4px",
    padding: "0px",
    fontSize: "18px",
    verticalAlign: "text-top"
  },
  redColorText: {
    color: "#FF0000"
  },
  greenColorText: {
    color: "#32CD32"
  },
  silverColorText: {
    color: "#C0C0C0"
  }
});

import { createStyles, Theme } from "@material-ui/core/styles";

export const statisticPagestyles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "0px",
      padding: "0px",
      overflow: "auto"
    },
    dateBar: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      margin: "12px 0px 8px 0px"
    },
    dateGrid: {
      width: "240px",
      margin: "0px 12px 0px 0px"
    },
    contentWrapper: {
      width: "100%",
      margin: "0px",
      padding: "0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    conversationPaper: {
      width: 600,
      margin: "auto",
      overflow: "hidden"
    },
    messagesContainer: {
      margin: "4px 0px 4px 0px",
      padding: 0,
      width: "100%",
      height: 700,
      overflowX: "hidden"
    },
    conversationInfo: {
      lineHeight: "100px",
      height: 200
    },
    alertDetailsInfo: {
      margin: "24px 16px 0px 16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    block: {
      display: "block"
    }
  });

import _, { uniq } from "lodash";

import { axiosAuthInstanceService } from "../config/axios.config";
import { IMessage } from "../interfaces";
import {
  mergeUserAlerts,
  mergeStocks,
  getPopularStocks,
  getMetricsTableData,
  manipulateConversations
} from "../utils";
import { IAlertDetails, IUser } from "../interfaces";
import { getTimestamptString } from "../utils";
import {
  viewDataFetchingStartedAction,
  fetchingFailedAction,
  usersFetchingCompletedAction,
  conversationsFetchingCompletedAction,
  dashUsersFetchingCompletedAction,
  stocksFetchingCompletedAction,
  popularStocksFCA,
  alertsFetchingCompletedAction,
  alertDetailsFetchingCompletedAction,
  tradeNowMFCA,
  menuTradeMTFCA,
  nonMenuTradeMTFCA,
  setPriceAlertMFCA,
  viewPriceAlertMFCA,
  priceQueryMFCA,
  marketInsightMFCA,
  resetViewData,
  metricsDataFCA,
  setPriceAlertMFCAInit,
  otherMetricDataFCA,
  usageMetricsDataFCS,
  ctaMetricsDataFCA,
  pnlDataFCA,
  stockProfitabilityDataFCA
} from "../actions";

export const fetchUsers = (viewDataPath: string) => async (dispatch: any) => {
  try {
    dispatch(resetViewData());
    dispatch(viewDataFetchingStartedAction());

    const res = await axiosAuthInstanceService().get(viewDataPath);

    if (!res.data || !res.data.users) {
      console.error("[Error] fetchUsers no data");
    }
    const { users } = res.data;

    const usersData: IUser[] = [];
    users.forEach((d: any) => {
      if (_.isEmpty(d.socialMediaId)) return;

      let onboardedStatus = "";
      let tutorialStatus = "";
      const userStatuses = d.user_statuses;

      if (!_.isEmpty(userStatuses)) {
        userStatuses.forEach((s: any) => {
          switch (s.name) {
            case "ONBOARDED":
              onboardedStatus = getTimestamptString(s.createdAt, "DD/MM/YYYY hh:mm A");
              break;
            case "TUTORIAL":
              tutorialStatus = getTimestamptString(s.createdAt, "DD/MM/YYYY hh:mm A");
              break;
            default:
              break;
          }
        });
      }

      const data: IUser = {
        clUserId: d.id,
        mobileNumber: d.socialMediaId,
        userName: d.username,
        onboardedStatus: onboardedStatus,
        tutorialStatus: tutorialStatus
      };

      usersData.push(data);
    });

    dispatch(usersFetchingCompletedAction(usersData));
  } catch (error) {
    console.error("[Error] fetchUsers", error);
    dispatch(fetchingFailedAction(error));
    dispatch(resetViewData());
  }
};

export const fetchConversations =
  (viewDataPath: string, startDate: string, endDate: string) => async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const res = await axiosAuthInstanceService().post(viewDataPath, {
        startDate: startDate,
        endDate: endDate
      });

      if (!res.data) {
        console.error("[Error] fetchConversations no data");
      }

      const conversData: IMessage[] = manipulateConversations(res.data);
      dispatch(conversationsFetchingCompletedAction(conversData));
    } catch (error) {
      console.error("[Error] fetchConversations", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchDashUsers = (viewDataPath: string) => async (dispatch: any) => {
  try {
    dispatch(resetViewData());
    dispatch(viewDataFetchingStartedAction());

    const res = await axiosAuthInstanceService().get(viewDataPath);

    if (!res.data || !res.data.dashboard_users) {
      console.error("[Error] fetchDashUsers no data");
    }

    dispatch(dashUsersFetchingCompletedAction(res.data.dashboard_users));
  } catch (error) {
    console.error("[Error] fetchDashUsers", error);
    dispatch(fetchingFailedAction(error));
    dispatch(resetViewData());
  }
};

export const fetchStocks =
  (summaryPath: string, priceQueryPath: string, startDate: string, endDate: string) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const stockData = {
        start_date: startDate,
        end_date: endDate,
        type: "summary"
      };

      const summaryRes = await axiosAuthInstanceService().post(summaryPath, stockData);
      const priceQueryRes = await axiosAuthInstanceService().post(
        priceQueryPath,
        stockData
      );

      if (!summaryRes.data || !priceQueryRes.data) {
        console.error("[Error] fetchStocks no data");
      }

      const totalStocks = mergeStocks(summaryRes.data, priceQueryRes.data);

      dispatch(stocksFetchingCompletedAction(totalStocks));
    } catch (error) {
      console.error("[Error] fetchStocks", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchPopularStocks =
  (summaryPath: string, priceQueryPath: string, startDate: string, endDate: string) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const stockData = {
        start_date: startDate,
        end_date: endDate,
        type: "summary"
      };
      const [summaryRes, priceQueryRes] = await Promise.all([
        axiosAuthInstanceService().post(summaryPath, stockData),
        axiosAuthInstanceService().post(priceQueryPath, stockData)
      ]);

      if (!summaryRes.data || !priceQueryRes.data) {
        console.error("[Error] fetchPopularStocks no data");
      }

      const popularStocks = getPopularStocks(summaryRes.data, priceQueryRes.data);

      dispatch(popularStocksFCA(popularStocks));
    } catch (error) {
      console.error("[Error] fetchPopularStocks", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchAlerts =
  (alertPath: string, queryPath: string, startDate: string, endDate: string) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const activeAlertData = {
        start_date: startDate,
        end_date: endDate,
        type: "summary",
        inactive: false
      };
      const inactiveAlertData = {
        start_date: startDate,
        end_date: endDate,
        type: "summary",
        inactive: true
      };
      const priceQueryData = {
        start_date: startDate,
        end_date: endDate
      };
      const activeAlertRes = await axiosAuthInstanceService().post(
        alertPath,
        activeAlertData
      );
      const inactiveAlertRes = await axiosAuthInstanceService().post(
        alertPath,
        inactiveAlertData
      );
      const priceQueryRes = await axiosAuthInstanceService().post(
        queryPath,
        priceQueryData
      );

      if (!activeAlertRes.data || !inactiveAlertRes.data || !priceQueryRes.data) {
        console.error("[Error] fetchAlerts no data");
      }

      const totalAlerts = mergeUserAlerts(
        activeAlertRes.data,
        inactiveAlertRes.data,
        priceQueryRes.data
      );

      dispatch(alertsFetchingCompletedAction(totalAlerts));

      // dispatch(alertsFetchingCompletedAction(res.data));
    } catch (error) {
      console.error("[Error] fetchAlerts", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchAlertDetails =
  (alertPath: string, startDate: string, endDate: string, userId: number) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const alertDetailsData = {
        start_date: startDate,
        end_date: endDate,
        type: "detail",
        user_id: userId
      };

      const res = await axiosAuthInstanceService().post(alertPath, alertDetailsData);

      if (!res.data) {
        console.error("[Error] fetchAlertDetails no data");
      }

      const alerDetailsData: IAlertDetails[] = [];
      res.data.forEach((item: any) => {
        const data: IAlertDetails = {
          status: item.hit_time ? "hit" : "active",
          hitDate: getTimestamptString(item.hit_time, "DD/MM/YYYY hh:mm A"),
          activeCreatedDate: getTimestamptString(item.created_at, "DD/MM/YYYY hh:mm A"),
          stockTicker: item.key,
          priceTarget: item.price_target
        };

        alerDetailsData.push(data);
      });

      dispatch(alertDetailsFetchingCompletedAction(alerDetailsData));
    } catch (error) {
      console.error("[Error] fetchAlertDetails", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchTradeNowM =
  (
    apiPath: string,
    startDate: string,
    endDate: string,
    metricType: string,
    metricCategory: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const requestPayload = {
        start_date: startDate,
        end_date: endDate,
        type: metricType,
        category: metricCategory
      };

      const res = await axiosAuthInstanceService().post(apiPath, requestPayload);

      if (!res.data) {
        console.error("[Error] fetchTradeNowM no data");
      }

      dispatch(tradeNowMFCA(res.data));
    } catch (error) {
      console.error("[Error] fetchTradeNowM", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchTradeNowMT =
  (apiPath: string, startDate: string, endDate: string) => async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const initNonMenuRP = {
        start_date: startDate,
        end_date: endDate,
        type: "summary",
        category: "trade_init_nonmenu"
      };
      const completeNonMenuRP = {
        start_date: startDate,
        end_date: endDate,
        type: "summary",
        category: "trade_complete_nonmenu"
      };
      const initMenuRP = {
        start_date: startDate,
        end_date: endDate,
        type: "summary",
        category: "trade_init_menu"
      };
      const completeMenuRP = {
        start_date: startDate,
        end_date: endDate,
        type: "summary",
        category: "trade_complete_menu"
      };

      const initNonMenuRes = await axiosAuthInstanceService().post(
        apiPath,
        initNonMenuRP
      );
      const completeNonMenuRes = await axiosAuthInstanceService().post(
        apiPath,
        completeNonMenuRP
      );
      const initMenuRes = await axiosAuthInstanceService().post(apiPath, initMenuRP);
      const completeMenuRes = await axiosAuthInstanceService().post(
        apiPath,
        completeMenuRP
      );

      const TradeByMenuData: any = getMetricsTableData(
        initMenuRes.data,
        completeMenuRes.data
      );

      dispatch(menuTradeMTFCA(TradeByMenuData));

      const TradeByNonMenuData: any = getMetricsTableData(
        initNonMenuRes.data,
        completeNonMenuRes.data
      );

      dispatch(nonMenuTradeMTFCA(TradeByNonMenuData));
    } catch (error) {
      console.error("[Error] fetchTradeNowMT", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchSetPriceAlertM =
  (
    apiPath: string,
    startDate: string,
    endDate: string,
    metricType: string,
    metricCategory: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const requestPayload = {
        start_date: startDate,
        end_date: endDate,
        type: metricType,
        category: metricCategory
      };

      const res = await axiosAuthInstanceService().post(apiPath, requestPayload);

      if (!res.data) {
        console.error("[Error] fetchSetPriceAlertM no data");
      }

      dispatch(setPriceAlertMFCA(res.data));
    } catch (error) {
      console.error("[Error] fetchSetPriceAlertM", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchSetPriceAlertInitM =
  (
    apiPath: string,
    startDate: string,
    endDate: string,
    metricType: string,
    metricCategory: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const requestPayload = {
        start_date: startDate,
        end_date: endDate,
        type: metricType,
        category: metricCategory
      };

      const res = await axiosAuthInstanceService().post(apiPath, requestPayload);

      if (!res.data) {
        console.error("[Error] fetchSetPriceAlertM no data");
      }

      dispatch(setPriceAlertMFCAInit(res.data));
    } catch (error) {
      console.error("[Error] fetchSetPriceAlertM", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchViewPriceAlertM =
  (
    apiPath: string,
    startDate: string,
    endDate: string,
    metricType: string,
    metricCategory: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const requestPayload = {
        start_date: startDate,
        end_date: endDate,
        type: metricType,
        category: metricCategory
      };

      const res = await axiosAuthInstanceService().post(apiPath, requestPayload);

      if (!res.data) {
        console.error("[Error] fetchViewPriceAlertM no data");
      }

      dispatch(viewPriceAlertMFCA(res.data));
    } catch (error) {
      console.error("[Error] fetchViewPriceAlertM", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchPriceQueryM =
  (
    apiPath: string,
    startDate: string,
    endDate: string,
    metricType: string,
    metricCategory: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const requestPayload = {
        start_date: startDate,
        end_date: endDate,
        type: metricType,
        category: metricCategory
      };

      const res = await axiosAuthInstanceService().post(apiPath, requestPayload);

      let data = (
        res.data as {
          category: object;
          data: Array<{ language: string; market: any; [x: string]: string }>;
        }
      ).data.filter((x) => x.language !== "en" && x.market !== null);
      dispatch(priceQueryMFCA(data));
    } catch (error) {
      console.error("[Error] fetchPriceQueryM", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchMarketInsightM =
  (
    apiPath: string,
    startDate: string,
    endDate: string,
    metricType: string,
    metricCategory: [string, string] | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const requestPayload_US = {
        start_date: startDate,
        end_date: endDate,
        type: metricType,
        category: metricCategory[0]
      };
      const requestPayload_HK = {
        start_date: startDate,
        end_date: endDate,
        type: metricType,
        category: metricCategory[1]
      };

      const [resUS, resHK] = await Promise.all([
        axiosAuthInstanceService().post(apiPath, requestPayload_US),
        axiosAuthInstanceService().post(apiPath, requestPayload_HK)
      ]);

      if (!resUS.data || !resHK.data) {
        console.error("[Error] fetchMarketInsightM no data");
      }
      const parseParametersByLanguage = (lan: "en-gb" | "zh-hk") => (a: any, b: any) => {
        if (b.language && b.language.toLowerCase() === lan && b.parameters) {
          const parametersA = a.parameters ? JSON.parse(a.parameters) : a ? a : {};
          const parametersB = JSON.parse(b.parameters);
          const keysA = Object.keys(parametersA);
          const keysB = Object.keys(parametersB);
          const uniqKeys = uniq(keysA.concat(keysB));
          const parameters = uniqKeys.reduce((c: any, d: any) => {
            c[d] = (parametersA[d] || 0) + (parametersB[d] || 0);
            return c;
          }, {});

          return parameters;
        }
        return a;
      };
      const insightsUS = resUS.data.data;
      const insightsHK = resHK.data.data;

      let insightsUS_en = insightsUS.reduce(parseParametersByLanguage("en-gb"), {});
      let insightsUS_hk = insightsUS.reduce(parseParametersByLanguage("zh-hk"), {});
      let insightsHK_en = insightsHK.reduce(parseParametersByLanguage("en-gb"), {});
      let insightsHK_hk = insightsHK.reduce(parseParametersByLanguage("zh-hk"), {});

      const insights = [
        // top 5 gainers
        { insight: "topgainers", title: "Top 5 Gainers", usMarket: [], hkMarket: [] },
        // top 5 losers
        { insight: "toplosers", title: "Top 5 Losers" },
        // top 5 highest div yields
        {
          insight: "highestdiv",
          title: "Top 5 Highest Dividend Yield Stocks"
        },
        // top 5 most traded healthcare stocks
        {
          insight: "mostactive_healthcare",
          title: "Top 5 Most Traded Healthcare Stocks"
        },
        // top 5 most traded tech
        {
          insight: "mostactive_tech",
          title: "Top 5 Most Traded Tech Stocks"
        },
        // top 5 most traded REITS
        {
          insight: "mostactive_reits",
          title: "Top 5 Most Traded REITS Stocks"
        },
        // top 5 most traded Stocks
        { insight: "mostactive", title: "Top 5 Most Traded Stocks" },
        // top 5 most traded ETFS
        { insight: "mostactive_etf", title: "Top 5 Most Traded ETFs" },
        // month to date top 5 gainers
        { insight: "topgainers_mtd", title: "Month-to-Date top 5 Gainers" },
        // month to date top 5 losers
        { insight: "toplosers_mtd", title: "Month-to-Date top 5 Losers" },
        // year to year top 5 gainers
        { insight: "topgainers_ytd", title: "Year-to-Date top 5 Gainers" },
        // year to year top 5 losers
        { insight: "toplosers_ytd", title: "Year-to-Date top 5 Losers" },
        // DBS research analysts' pick
        { insight: "dbs", title: "DBS Research Analysts' Pick" }
      ].map((data) => {
        return {
          ...data,
          hkMarket: [insightsHK_en[data.insight] || 0, insightsHK_hk[data.insight] || 0],
          usMarket: [insightsUS_en[data.insight] || 0, insightsUS_hk[data.insight] || 0]
        };
      });

      dispatch(marketInsightMFCA(insights));
    } catch (error) {
      console.error("[Error] fetchMarketInsightM", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchMetricsSummary =
  (
    apiPath: string,
    startDate: string,
    endDate: string,
    metricType: string,
    metricCategory: Array<string>
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const summaryResponses = await Promise.all(
        metricCategory.map(async (x) => {
          const payload = {
            start_date: startDate,
            end_date: endDate,
            type: metricType,
            category: x
          };

          let res: any;
          let data = { category: "", data: {} };
          switch (x) {
            case "active_users":
              res = await axiosAuthInstanceService().post(
                "/dashboard/analytics/users",
                payload
              );
              if (!res.data) {
                console.error(`[Error] ${metricCategory} no data`);
              }
              data.category = "active_users";
              data.data = res.data;
              break;
            case "usage_duration":
              res = await axiosAuthInstanceService().post(
                "/dashboard/analytics/users/usage",
                payload
              );
              if (!res.data) {
                console.error(`[Error] ${metricCategory} no data`);
              }
              data.category = "usage_duration";
              data.data = res.data;
              break;
            default:
              res = await axiosAuthInstanceService().post(apiPath, payload);
              if (!res.data) {
                console.error(`[Error] ${metricCategory} no data`);
              }
              data.category = res.data.category;
              data.data = res.data.data;
              break;
          }
          return data;
        })
      );

      dispatch(metricsDataFCA(summaryResponses));
    } catch (error) {
      console.error("[Error] fetchMetricsSummary", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchOtherMetrics =
  (
    apiPath: string,
    startDate: string,
    endDate: string,
    metricType: string,
    metricCategory: Array<string>
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const summaryResponses = await Promise.all(
        metricCategory.map(async (x) => {
          const payload = {
            start_date: startDate,
            end_date: endDate,
            type: metricType,
            category: x
          };

          let res;
          let data;
          if (x === "users_usage") {
            res = await axiosAuthInstanceService().post(
              "/dashboard/analytics/users/usage",
              payload
            );
            if (!res.data) {
              console.error(`[Error] ${metricCategory} no data`);
            }
            data = res.data;
          } else {
            res = await axiosAuthInstanceService().post(apiPath, payload);
            if (!res.data) {
              console.error(`[Error] ${metricCategory} no data`);
            }
            data = (
              res.data as {
                category: object;
                data: Array<{ language: string; market: any; [x: string]: string }>;
              }
            ).data.filter((x) => x.language !== "en" && x.language !== "zh");
          }

          return { [x]: data };
        })
      );

      dispatch(otherMetricDataFCA(summaryResponses));
    } catch (error) {
      console.error("[Error] fetchOtherMetrics", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchUsageMetrics =
  (startDate: string, endDate: string, metricType: string) => async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const requestPayload = {
        start_date: startDate,
        end_date: endDate,
        type: metricType
      };

      const res = await axiosAuthInstanceService().post(
        "/dashboard/analytics/users",
        requestPayload
      );

      dispatch(usageMetricsDataFCS(res.data.result));
    } catch (error) {
      console.error("[Error] fetchUsageMetrics", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchCTAMetrics =
  (startDate: string, endDate: string, metricType: string) => async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());
      const metricCategory = ["init", "complete"];
      const summaryResponses = await Promise.all(
        metricCategory.map(async (x) => {
          const payload = {
            start_date: startDate,
            end_date: endDate,
            type: metricType,
            category: x
          };
          // "id": 373,
          // "action": "",
          // "intent": "cta-complete",
          // "daily": "2021-07-27T16:00:00.000Z",
          // "language": "en-gb",
          // "market": "HK",
          // "count": "6",
          // "parameters": "{\"dbs\":2,\"pweb\":4}"
          let res = await axiosAuthInstanceService().post(
            "/dashboard/analytics/cta",
            payload
          );
          if (!res.data) {
            console.error(`[Error] ${metricCategory} no data`);
          }
          let data = (
            res.data as { category: string; data: Array<{ [key: string]: string }> }
          ).data.filter((x) => x.language !== "en" && x.language !== "zh");
          data = data.map((x) => {
            return { ...x, parameters: JSON.parse(x.parameters) };
          });
          return { [x]: data };
        })
      );

      dispatch(ctaMetricsDataFCA(summaryResponses));
    } catch (error) {
      console.error("[Error] fetchCTAMetrics", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchPNLRankings =
  (apiPath: string, startDate: string, endDate: string) => async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const res = await axiosAuthInstanceService().post(apiPath);

      let data = res.data.filter(
        (x: any) =>
          new Date(x.rank_date) >= new Date(startDate) &&
          new Date(x.rank_date) <= new Date(endDate)
      );

      dispatch(pnlDataFCA(data));
    } catch (error) {
      console.error("[Error] fetchPNLRankings", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

export const fetchStockProfitabilities =
  (apiPath: string, startDate: string, endDate: string) => async (dispatch: any) => {
    try {
      dispatch(resetViewData());
      dispatch(viewDataFetchingStartedAction());

      const res = await axiosAuthInstanceService().post(apiPath);

      let data = res.data.filter(
        (x: any) =>
          new Date(x.inserted) >= new Date(startDate) &&
          new Date(x.inserted) <= new Date(endDate)
      );

      dispatch(stockProfitabilityDataFCA(data));
    } catch (error) {
      console.error("[Error] fetchStockProfitabilities", error);
      dispatch(fetchingFailedAction(error));
      dispatch(resetViewData());
    }
  };

import { axiosAuthInstanceService } from "../config/axios.config";

export const registerDashUser = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axiosAuthInstanceService().post(endpoint, data);

    if (!response.data && !response.data.dashboard_user) {
      console.warn("registerDashUser[Info] No data");
    }

    return await response.data.dashboard_user;
  } catch (error) {
    console.error("registerDashUser[Error]:", error);
    return [];
  }
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import { Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
import { systemUpdateCurrentPath } from "../../actions";
import { IDefaultRootState } from "../../interfaces";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import {
  fetchMetricsSummary,
  fetchPopularStocks,
  fetchUsageMetrics,
  fetchMarketInsightM
} from "../../asyncActions";
import { metricPageStyles } from "../../layouts/MetricPage.style";
import {
  getTimestamptString,
  getCategoryCountsByLanguage,
  getDecimalValues,
  getDaysBetweenDates
} from "../../utils";
import CumulativeTable from "../../components/CumulativeTable";
import ActiveUsersMChart from "../../components/ActiveUsersMChart";
import { Loading } from "../../components/Loading";
import OverviewBriefInfo from "../../components/OverviewBriefInfo";

interface MetricsSummaryProps extends WithStyles<typeof metricPageStyles> {
  viewData: any;
  system: any;
}

const cumulativeMetrics: any = {
  usage_duration: {
    categoryName: "Usage Duration",
    en: "NA",
    zh: "NA",
    tooltipMessage:
      "A calculation of the increase and decrease usage by active users based on the selected date range. Negative number represents declining usage, while positive number represents increase in usage."
  },
  user_calculation: {
    categoryName: "User Calculation",
    en: "NA",
    zh: "NA",
    tooltipMessage: ""
  },
  active_users: {
    categoryName: "Active User",
    en: 0,
    zh: 0,
    tooltipMessage:
      "An accumulative sum of the active users based on the selected date range."
  },
  total_action: { categoryName: "Total action", en: 0, zh: 0 },
  trade_complete: {
    categoryName: "Trade now",
    en: 0,
    zh: 0
  },
  set_alert_complete: { categoryName: "Set Price Alert", en: 0, zh: 0 },
  view_alert: { categoryName: "View Price alert", en: 0, zh: 0 },
  view_price: { categoryName: "Show Stock Price", en: 0, zh: 0 },
  market_insight: { categoryName: "Market Insight", en: 0, zh: 0 },
  recent_trades: { categoryName: "Recent Trade", en: 0, zh: 0 },
  view_research: { categoryName: "View Research ", en: 0, zh: 0 },
  help: { categoryName: "Help", en: 0, zh: 0 },
  view_portfolio: { categoryName: "View Portfolio", en: 0, zh: 0 }
};

function DashboardContent(props: MetricsSummaryProps) {
  const dispatch = useDispatch();
  const { classes } = props;
  const mainLayoutClasses = mainLayoutStyles();

  const system = useSelector((state: IDefaultRootState) => state.system);
  const viewData = useSelector((state: IDefaultRootState) => state.viewData);

  const { accessibleApis } = system;
  const { metricsAnalyticsEndpoint } = accessibleApis;

  const { metricsSummary, usageMetrics, popularStocks, marketInsightMetrics } = viewData;
  const { stocksSummaryEndpoint, stocksPriceQueryEndpoint } = accessibleApis;

  const [isInitialRender, setisInitialRender] = useState(true);

  const defaultStartDate: Date = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate: Date = new Date();
  const defaultStartDateString = moment
    .tz(defaultStartDate, "Asia/Hong_Kong")
    .startOf("day")
    .toISOString();
  const defaultEndDateString = moment
    .tz(defaultEndDate, "Asia/Hong_Kong")
    .endOf("day")
    .toISOString();

  const [selectedStartDate, setselectedStartDate] =
    useState<string>(defaultStartDateString);
  const [selectedEndDate, setselectedEndDate] = useState<string>(defaultEndDateString);

  const [chartSeries, setchartSeries] = useState(null);
  const [chartPopulatedDates, setchartPopulatedDates] = useState([]);
  const [chartMaxAmountNumber, setchartMaxAmountNumber] = useState(0);
  const [cumulativeTableData, setcumulativeTableData] = useState([]);
  const [isTableLoading, setisTableLoading] = useState(true);
  const [isChartLoading, setisChartLoading] = useState(true);
  const [isChartLoaded, setisChartLoaded] = useState(false);
  const [briefInfo, setbriefInfo] = useState(null);
  const [isBriefInfoLoaded, setisBriefInfoLoaded] = useState(false);

  const handleSearch = () => {
    try {
      if (!selectedStartDate || !selectedEndDate) return;
      setisTableLoading(true);
      setisChartLoading(true);

      dispatch(
        fetchMetricsSummary(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          [
            "set_alert_complete",
            "trade_complete",
            "view_alert",
            "view_price",
            "insight_US",
            "insight_HK",
            "recent_trades",
            "help",
            "active_users",
            "view_portfolio",
            "usage_duration"
          ]
        )
      );

      dispatch(fetchUsageMetrics(selectedStartDate, selectedEndDate, "daily"));

      dispatch(
        fetchMarketInsightM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "detail",
          ["insight_US", "insight_HK"]
        )
      );

      dispatch(
        fetchPopularStocks(
          stocksSummaryEndpoint,
          stocksPriceQueryEndpoint,
          selectedStartDate,
          selectedEndDate
        )
      );
    } catch (error) {}
  };

  const handlePickerOnChange = (rangeSelection: any) => {
    try {
      const startDate: Date = rangeSelection.startDate;
      const endDate: Date = rangeSelection.endDate;

      const startDateString = moment
        .tz(startDate, "Asia/Hong_Kong")
        .startOf("day")
        .toISOString();
      const endDateString = moment
        .tz(endDate, "Asia/Hong_Kong")
        .endOf("day")
        .toISOString();
      setselectedStartDate(startDateString);
      setselectedEndDate(endDateString);
    } catch (error) {
      console.error("[Error] Overview handlePickerOnChange");
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);
      if (!selectedStartDate || !selectedEndDate) return;
      setisTableLoading(true);
      setisChartLoading(true);

      dispatch(
        fetchMetricsSummary(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          [
            "set_alert_complete",
            "trade_complete",
            "view_alert",
            "view_price",
            "insight_US",
            "insight_HK",
            "recent_trades",
            "help",
            "active_users",
            "view_portfolio",
            "usage_duration"
          ]
        )
      );

      dispatch(fetchUsageMetrics(selectedStartDate, selectedEndDate, "daily"));

      dispatch(
        fetchMarketInsightM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "detail",
          ["insight_US", "insight_HK"]
        )
      );

      dispatch(
        fetchPopularStocks(
          stocksSummaryEndpoint,
          stocksPriceQueryEndpoint,
          selectedStartDate,
          selectedEndDate
        )
      );
    }
  }, [
    dispatch,
    metricsAnalyticsEndpoint,
    isInitialRender,
    selectedStartDate,
    selectedEndDate,
    stocksPriceQueryEndpoint,
    stocksSummaryEndpoint
  ]);

  // Update view_research in cumulativeMetrics data
  useEffect(() => {
    if (!Object.keys(marketInsightMetrics).length) return;

    const researchData: any = marketInsightMetrics.find(
      (item: any) => item.insight === "dbs"
    );

    if (_.isEmpty(researchData)) return;
    cumulativeMetrics.view_research["en"] =
      researchData.hkMarket[0] + researchData.usMarket[0];
    cumulativeMetrics.view_research["zh"] =
      researchData.hkMarket[1] + researchData.usMarket[1];
  }, [marketInsightMetrics]);

  // Update others cumulativeMetrics data
  useEffect(() => {
    try {
      if (!metricsSummary.length) return;

      metricsSummary.forEach((metric: any) => {
        switch (metric.category) {
          case "usage_duration":
            // usage duration
            cumulativeMetrics.usage_duration["en"] = getDecimalValues(
              metric.data.usage["en-GB"]
            );
            cumulativeMetrics.usage_duration["zh"] = getDecimalValues(
              metric.data.usage["zh-HK"]
            );
            // user calculation
            cumulativeMetrics.user_calculation["en"] = getDecimalValues(
              metric.data.user_calc["en-GB"]
            );
            cumulativeMetrics.user_calculation["zh"] = getDecimalValues(
              metric.data.user_calc["zh-HK"]
            );
            break;
          case "active_users":
            const activeUsersByLang = getCategoryCountsByLanguage(
              metric.data.result,
              "count"
            );
            cumulativeMetrics.active_users["en"] = activeUsersByLang.enCount;
            cumulativeMetrics.active_users["zh"] = activeUsersByLang.zhCount;
            break;
          case "insight_US":
            const usValuesByLang = getCategoryCountsByLanguage(
              metric.data,
              metric.category
            );
            cumulativeMetrics.market_insight["en"] = usValuesByLang.enCount;
            cumulativeMetrics.market_insight["zh"] = usValuesByLang.zhCount;
            break;
          case "insight_HK":
            const hkValuesByLang = getCategoryCountsByLanguage(
              metric.data,
              metric.category
            );
            cumulativeMetrics.market_insight["en"] =
              hkValuesByLang.enCount + cumulativeMetrics.market_insight["en"];
            cumulativeMetrics.market_insight["zh"] =
              hkValuesByLang.zhCount + cumulativeMetrics.market_insight["zh"];
            break;
          default:
            const valuesByLanguage = getCategoryCountsByLanguage(
              metric.data,
              metric.category
            );
            cumulativeMetrics[metric.category]["en"] = valuesByLanguage.enCount;
            cumulativeMetrics[metric.category]["zh"] = valuesByLanguage.zhCount;
            break;
        }
      });
    } catch (error) {
      console.error("[Error] cumulativeMetrics update", error);
    }
  }, [metricsSummary]);

  // update total_action in cumulativeMetrics data
  useEffect(() => {
    if (!metricsSummary.length) return;

    let totalActionEnCount = 0;
    let totalActionZhCount = 0;
    Object.keys(cumulativeMetrics).forEach((mCategory: string) => {
      if (
        mCategory === "usage_duration" ||
        mCategory === "user_calculation" ||
        mCategory === "active_users" ||
        mCategory === "total_action"
      ) {
        return;
      }

      const metric = cumulativeMetrics[mCategory];
      totalActionEnCount += metric.en;
      totalActionZhCount += metric.zh;
    });
    cumulativeMetrics.total_action.en = totalActionEnCount;
    cumulativeMetrics.total_action.zh = totalActionZhCount;

    setisTableLoading(false);
  }, [metricsSummary]);

  useEffect(() => {
    if (_.isEmpty(usageMetrics)) return;
    setisChartLoaded(false);
  }, [usageMetrics]);

  useEffect(() => {
    if (_.isEmpty(popularStocks)) return;
    setisBriefInfoLoaded(false);
  }, [popularStocks]);

  // Chart Data loading
  useEffect(() => {
    if (_.isEmpty(usageMetrics)) return;

    if (isChartLoaded) return;

    const formattedData = usageMetrics.map((item: any) => {
      item.date = getTimestamptString(item.date, "YYYY-MM-DD");
      return item;
    });

    // REF: https://stackoverflow.com/a/10124053/3269542
    const ascendingData = formattedData.sort(
      (a: any, b: any) => +new Date(a.date) - +new Date(b.date)
    );

    const populatedDates: any = getDaysBetweenDates(
      getTimestamptString(selectedStartDate, "YYYY-MM-DD"),
      getTimestamptString(selectedEndDate, "YYYY-MM-DD")
    );

    const amountLabels = usageMetrics.map((d: any) => parseInt(d.count));
    const maxAmount: number = _.max(amountLabels) || 1;
    const maxAmountNumber = maxAmount + 2;

    const dataSeries: any = [];
    const seriesItems = usageMetrics.map((d: any) => d.language);
    const uniqueItems = _.uniq(seriesItems);
    const sortedSeriesItems: any = uniqueItems.sort();

    sortedSeriesItems.forEach((seriesItemName: string) => {
      const seriesItemArray: any = [];
      populatedDates.forEach((date: string) => {
        let seriesItemValue: number = 0;
        ascendingData.forEach((aData: any) => {
          if (aData.language === seriesItemName && aData.date === date && aData.count) {
            seriesItemValue = parseInt(aData.count);
          }
        });
        seriesItemArray.push(seriesItemValue);
      });
      let sName = "English";
      if (seriesItemName.toLowerCase() === "zh-hk") sName = "Cantonese";
      const seriesItem = { name: sName, data: seriesItemArray };
      dataSeries.push(seriesItem);
    });

    setchartPopulatedDates(populatedDates);
    setchartMaxAmountNumber(maxAmountNumber);
    setchartSeries(dataSeries);
    setisChartLoaded(true);
    setisChartLoading(false);
  }, [usageMetrics, isChartLoaded, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    const tableData: any = Object.entries(cumulativeMetrics);
    setcumulativeTableData(tableData);
  }, [metricsSummary]);

  // Brief Info loading
  useEffect(() => {
    if (_.isEmpty(popularStocks)) return;

    if (isBriefInfoLoaded) return;

    const usPopularStock = `${popularStocks?.usStock?.stockTicker} - ${popularStocks?.usStock?.stockFullName}`;
    const hkPopularStock = `${popularStocks?.hkStock?.stockTicker} - ${popularStocks?.hkStock?.stockFullName}`;

    const briefData: any = {
      startDate: getTimestamptString(selectedStartDate, "DD-MM-YYYY"),
      endDate: getTimestamptString(selectedEndDate, "DD-MM-YYYY"),
      enActiveUsers: cumulativeMetrics.active_users.en,
      zhActiveUsers: cumulativeMetrics.active_users.zh,
      usPopularStock,
      hkPopularStock
    };

    setbriefInfo(briefData);
    setisBriefInfoLoaded(true);
  }, [isBriefInfoLoaded, selectedStartDate, selectedEndDate, popularStocks]);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Overview" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Grid container className={classes.dateGridContainer} item>
          <Grid item className={classes.dateGridItem}>
            <CustomDateRangePicker handleOnChange={handlePickerOnChange} />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
        <OverviewBriefInfo briefInfo={briefInfo} />
        <Grid container>
          <Grid item lg md={6} sm={12} xs={12} style={{ marginBottom: "12px" }}>
            <Paper className={classes.overviewPaper}>
              <Typography className={classes.overviewTitle}>
                Daily Active Users
              </Typography>
              {isChartLoading && <Loading />}
              {!isChartLoading && (
                <ActiveUsersMChart
                  chartTitle={"ActiveUsersMChart"}
                  chartType={"line"}
                  series={chartSeries}
                  populatedDates={chartPopulatedDates}
                  maxAmountNumber={chartMaxAmountNumber}
                />
              )}
            </Paper>
          </Grid>
          <Grid item lg md={6} sm={12} xs={12} style={{ marginBottom: "12px" }}>
            <Paper className={classes.overviewPaper}>
              <Typography className={classes.overviewTitle}>
                Cumulative Overview
              </Typography>
              {isTableLoading && <Loading />}
              {!isTableLoading && <CumulativeTable data={cumulativeTableData} />}
            </Paper>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

export default withStyles(metricPageStyles)(DashboardContent);

import { createStyles, Theme } from "@material-ui/core/styles";

export const metricPageStyles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "0px",
      padding: "0px",
      overflow: "auto",
      height: "100%"
    },
    table: {
      margin: "0px",
      padding: "0px"
    },
    stickyCell: {
      position: "sticky",
      left: 0,
      zIndex: 1
    },
    cellWithWhiteBackground: {
      backgroundColor: "#fff"
    },
    cellWithGrayBackground: {
      backgroundColor: "#f4f5f7"
    },
    dateGridContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      margin: "0px auto 12px auto",
      padding: "8px 0px 8px 0px"
    },
    dateGridItem: {
      width: "240px",
      margin: "0px 12px 0px 0px"
    },
    dateGrid: {
      width: "240px",
      margin: "0px 12px 0px 0px"
    },
    contentWrapper: {
      padding: "0px",
      margin: "0px",
      width: "1024px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "flex-start",
      justifyContent: "space-between",
      alignItems: "flex-start"
    },
    overviewPaper: {
      padding: "8px",
      margin: "8px",
      height: "100%"
    },
    overviewTitle: {
      padding: "10px",
      fontSize: "15px",
      fontWeight: "bold",
      textAlign: "center"
    }
  });

import { connect } from "react-redux";

import Conversations from "./Conversations";

const mapStateToProps = (state: any) => ({
  system: state.system,
  viewData: state.viewData
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Conversations);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
import { IDefaultRootState } from "../../interfaces";
import {
  fetchSetPriceAlertInitM,
  fetchSetPriceAlertM,
  fetchViewPriceAlertM
} from "../../asyncActions";
import { systemUpdateCurrentPath } from "../../actions";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { metricPageStyles } from "../../layouts/MetricPage.style";

interface UsersProps extends WithStyles<typeof metricPageStyles> {
  viewData: any;
  system: any;
}

function SetPriceAlertMetric(props: UsersProps) {
  const dispatch = useDispatch();
  const { classes } = props;
  const mainLayoutClasses = mainLayoutStyles();

  const system = useSelector((state: IDefaultRootState) => state.system);
  const viewData = useSelector((state: IDefaultRootState) => state.viewData);
  const { accessibleApis } = system;
  const { metricsAnalyticsEndpoint } = accessibleApis;
  // const { isLoading } = viewData;
  const { setPriceAlertMetrics, viewPriceAlertMetrics, setPriceAlertInitMetrics } =
    viewData;

  const [isInitialRender, setisInitialRender] = useState(true);

  const defaultStartDate: Date = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate: Date = new Date();
  const defaultStartDateString = moment
    .tz(defaultStartDate, "Asia/Hong_Kong")
    .startOf("day")
    .toISOString();
  const defaultEndDateString = moment
    .tz(defaultEndDate, "Asia/Hong_Kong")
    .endOf("day")
    .toISOString();

  const [selectedStartDate, setselectedStartDate] =
    useState<string>(defaultStartDateString);
  const [selectedEndDate, setselectedEndDate] = useState<string>(defaultEndDateString);
  // const [chartSeries, setchartSeries] = useState(null);
  // const [chartPopulatedDates, setchartPopulatedDates] = useState([]);
  // const [chartMaxAmountNumber, setchartMaxAmountNumber] = useState(0);

  const handleSearch = () => {
    try {
      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchSetPriceAlertM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          "set_alert_complete"
        )
      );

      dispatch(
        fetchViewPriceAlertM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          "view_alert"
        )
      );

      dispatch(
        fetchSetPriceAlertInitM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          "set_alert_init"
        )
      );
    } catch (error) {}
  };

  const handlePickerOnChange = (rangeSelection: any) => {
    try {
      const startDate: Date = rangeSelection.startDate;
      const endDate: Date = rangeSelection.endDate;

      const startDateString = moment
        .tz(startDate, "Asia/Hong_Kong")
        .startOf("day")
        .toISOString();
      const endDateString = moment
        .tz(endDate, "Asia/Hong_Kong")
        .endOf("day")
        .toISOString();
      setselectedStartDate(startDateString);
      setselectedEndDate(endDateString);
    } catch (error) {
      console.error("[Error] SetPriceAlertMetric handlePickerOnChange");
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);

      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchSetPriceAlertM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          "set_alert_complete"
        )
      );

      dispatch(
        fetchViewPriceAlertM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          "view_alert"
        )
      );

      dispatch(
        fetchSetPriceAlertInitM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          "set_alert_init"
        )
      );
    }
  }, [
    dispatch,
    metricsAnalyticsEndpoint,
    isInitialRender,
    selectedStartDate,
    selectedEndDate
  ]);

  // NOTE: Temp disable chart

  // useEffect(() => {
  //   if (!data) return;
  //   const formattedData = data.map((item: any) => {
  //     item.daily = getTimestamptString(item.daily, "YYYY-MM-DD");
  //     return item;
  //   });

  //   // REF: https://stackoverflow.com/a/10124053/3269542
  //   const ascendingData = formattedData.sort(
  //     (a: any, b: any) => +new Date(a.daily) - +new Date(b.daily)
  //   );
  //   console.log("raw series", ascendingData);

  //   const minDate = ascendingData[0].daily;
  //   const maxDate = ascendingData[ascendingData.length - 1].daily;
  //   const populatedDates: any = getDaysBetweenDates(minDate, maxDate);
  //   console.log("categories populatedDates ", populatedDates);

  //   const amountLabels = data.map((d: any) => parseInt(d.trade_complete));
  //   const maxAmount: number = _.max(amountLabels) || 1;
  //   const maxAmountNumber = maxAmount * 1.5;
  //   console.log("maxAmountNumber", maxAmountNumber);

  //   const dataSeries: any = [];
  //   const seriesItems = data.map((d: any) => d.language);
  //   const uniqueItems = _.uniq(seriesItems);
  //   const sortedSeriesItems: any = uniqueItems.sort();
  //   console.log("Items", sortedSeriesItems);

  //   sortedSeriesItems.forEach((seriesItemName: string) => {
  //     const seriesItemArray: any = [];
  //     populatedDates.forEach((date: string) => {
  //       let seriesItemValue: number = 0;
  //       ascendingData.forEach((aData: any) => {
  //         if (aData.language === seriesItemName && aData.daily === date) {
  //           seriesItemValue = parseInt(aData.trade_complete);
  //         }
  //       });
  //       seriesItemArray.push(seriesItemValue);
  //     });
  //     const seriesItem = { name: seriesItemName, data: seriesItemArray };
  //     dataSeries.push(seriesItem);
  //   });
  //   console.log("dataSeries", dataSeries);

  //   setchartPopulatedDates(populatedDates);
  //   setchartMaxAmountNumber(maxAmountNumber);
  //   setchartSeries(dataSeries);
  // }, [data]);

  // useEffect(() => {
  //   dispatch(fetchMetricsSummary(
  //     metricsAnalyticsEndpoint,
  //     "2021-06-01T05:40:11.000Z",
  //     "2021-07-31T05:40:11.000Z",
  //     "summary",
  //     ["view_alert", "set_alert_complete"]
  //   ));
  // }, [dispatch]);

  const viewPriceAlert = {
    Total: {
      enCompleted: 0,
      zhCompleted: 0
    }
  };

  const setPriceAlert = {
    Total: {
      enInitiated: 0,
      enCompleted: 0,
      zhInitiated: 0,
      zhCompleted: 0
    },
    US: {
      enInitiated: 0,
      enCompleted: 0,
      zhInitiated: 0,
      zhCompleted: 0
    },
    HK: {
      enInitiated: 0,
      enCompleted: 0,
      zhInitiated: 0,
      zhCompleted: 0
    }
  };

  const groupedLanguages = _.groupBy(viewPriceAlertMetrics.data, function (b) {
    return b.language;
  });

  _.forEach(groupedLanguages, (value, key) => {
    if (key === "en-gb")
      viewPriceAlert.Total.enCompleted = value.reduce(
        (a, b) => a + Number(b["view_alert"] || 0),
        0
      );
    else if (key === "zh-hk")
      viewPriceAlert.Total.zhCompleted = value.reduce(
        (a, b) => a + Number(b["view_alert"] || 0),
        0
      );
  });

  const groupedLanguages2 = _.groupBy(setPriceAlertMetrics.data, function (b) {
    return b.language;
  });
  _.each(groupedLanguages2, (value, key) => {
    if (key === "en-gb") {
      const groupedMarket = _.groupBy(value, function (b) {
        return b.market;
      });
      if (groupedMarket.US)
        setPriceAlert.US.enCompleted = groupedMarket.US[0].set_alert_complete;
      if (groupedMarket.HK)
        setPriceAlert.HK.enCompleted = groupedMarket.HK[0].set_alert_complete;
    } else if (key === "zh-hk") {
      const groupedMarket = _.groupBy(value, function (b) {
        return b.market;
      });
      if (groupedMarket.US)
        setPriceAlert.US.zhCompleted = groupedMarket.US[0].set_alert_complete;
      if (groupedMarket.HK)
        setPriceAlert.HK.zhCompleted = groupedMarket.HK[0].set_alert_complete;
    }
  });

  _.each(
    _.groupBy(setPriceAlertInitMetrics.data, function (b) {
      return b.language;
    }),
    (value, key) => {
      if (key === "en-gb") {
        const groupedMarket = _.groupBy(value, function (b) {
          return b.market;
        });
        if (groupedMarket.US)
          setPriceAlert.US.enInitiated = groupedMarket.US[0].set_alert_init;
        if (groupedMarket.HK)
          setPriceAlert.HK.enInitiated = groupedMarket.HK[0].set_alert_init;
      } else if (key === "zh-hk") {
        const groupedMarket = _.groupBy(value, function (b) {
          return b.market;
        });
        if (groupedMarket.US)
          setPriceAlert.US.zhInitiated = groupedMarket.US[0].set_alert_init;
        if (groupedMarket.HK)
          setPriceAlert.HK.zhInitiated = groupedMarket.HK[0].set_alert_init;
      }
    }
  );

  setPriceAlert.Total.enInitiated =
    Number(setPriceAlert.US.enInitiated) + Number(setPriceAlert.HK.enInitiated);
  setPriceAlert.Total.enCompleted =
    Number(setPriceAlert.US.enCompleted) + Number(setPriceAlert.HK.enCompleted);
  setPriceAlert.Total.zhInitiated =
    Number(setPriceAlert.US.zhInitiated) + Number(setPriceAlert.HK.zhInitiated);
  setPriceAlert.Total.zhCompleted =
    Number(setPriceAlert.US.zhCompleted) + Number(setPriceAlert.HK.zhCompleted);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Price Alert" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Grid container className={classes.dateGridContainer}>
          <Grid item className={classes.dateGridItem}>
            <CustomDateRangePicker handleOnChange={handlePickerOnChange} />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
        {/* <div className={classes.contentWrapper}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MetricsChart
                          chartTitle={"example2"}
                          chartType={"line"}
                          series={chartSeries}
                          populatedDates={chartPopulatedDates}
                          maxAmountNumber={chartMaxAmountNumber}
                        />
                      </Grid>
                    </Grid>
                  </div> */}
        {/* {!isLoading && _.isEmpty(data) && (
              <div className={classes.contentWrapper}>
                <Typography color="textSecondary" align="center">
                  No Data
                </Typography>
              </div>
            )}
            {!isLoading && !_.isEmpty(data) && (
              <div className={classes.contentWrapper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MetricsChart
                      chartTitle={"example2"}
                      chartType={"line"}
                      series={chartSeries}
                      populatedDates={chartPopulatedDates}
                      maxAmountNumber={chartMaxAmountNumber}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Paper>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell style={{fontWeight:"bold"}} align="center" colSpan={2}>English</TableCell>
                              <TableCell style={{fontWeight:"bold"}} align="center" colSpan={2}>Cantonese</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                              <TableCell></TableCell>
                              <TableCell>
                                Initiated
                              </TableCell>
                              <TableCell>
                                Completed
                              </TableCell>
                              <TableCell>
                                Initiated
                              </TableCell>
                              <TableCell>
                                Completed
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                              <TableCell>
                                View Price Alert
                              </TableCell>
                              {Object.entries(tradeByMenu.Total).map(([key, value]) =>
                                <TableCell style={{fontWeight:"bold"}}>{value}</TableCell>
                              )}
                            </TableRow>
                            <TableRow>
                              <TableCell>US Stocks</TableCell>
                              {Object.entries(tradeByMenu.US).map(([key, value]) =>
                                <TableCell>{value}</TableCell>
                              )}
                            </TableRow>
                            <TableRow>
                              <TableCell>HK Stocks</TableCell>
                              {Object.entries(tradeByMenu.HK).map(([key, value]) =>
                                <TableCell>{value}</TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell style={{fontWeight:"bold"}} align="center" colSpan={2}>English</TableCell>
                              <TableCell style={{fontWeight:"bold"}} align="center" colSpan={2}>Cantonese</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                              <TableCell></TableCell>
                              <TableCell>
                                Initiated
                              </TableCell>
                              <TableCell>
                                Completed
                              </TableCell>
                              <TableCell>
                                Initiated
                              </TableCell>
                              <TableCell>
                                Completed
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                              <TableCell>
                                Set Price Alert
                              </TableCell>
                              {Object.entries(tradeByMenu.Total).map(([key, value]) =>
                                <TableCell style={{fontWeight:"bold"}}>{value}</TableCell>
                              )}
                            </TableRow>
                            <TableRow>
                              <TableCell>US Stocks</TableCell>
                              {Object.entries(tradeByMenu.US).map(([key, value]) =>
                                <TableCell>{value}</TableCell>
                              )}
                            </TableRow>
                            <TableRow>
                              <TableCell>HK Stocks</TableCell>
                              {Object.entries(tradeByMenu.HK).map(([key, value]) =>
                                <TableCell>{value}</TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )} */}
        <Grid container alignItems={"stretch"} spacing={1}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center" colSpan={1}>
                      English
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Cantonese
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Completed</TableCell>
                    <TableCell align="center">Completed</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellWithGrayBackground}>
                      View Price Alert
                    </TableCell>
                    {Object.entries(viewPriceAlert.Total).map(([key, value]) => (
                      <TableCell key={key} align="center" style={{ fontWeight: "bold" }}>
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center" colSpan={2}>
                      English
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                      Cantonese
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Initiated</TableCell>
                    <TableCell align="center">Completed</TableCell>
                    <TableCell align="center">Initiated</TableCell>
                    <TableCell align="center">Completed</TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                    <TableCell className={classes.cellWithGrayBackground}>
                      Set Price Alert
                    </TableCell>
                    {Object.entries(setPriceAlert.Total).map(([key, value]) => (
                      <TableCell key={key} style={{ fontWeight: "bold" }} align="center">
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellWithGrayBackground}>
                      US Stocks
                    </TableCell>
                    {Object.entries(setPriceAlert.US).map(([key, value]) => (
                      <TableCell key={key} align="center">
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellWithGrayBackground}>
                      HK Stocks
                    </TableCell>
                    {Object.entries(setPriceAlert.HK).map(([key, value]) => (
                      <TableCell key={key} align="center">
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

export default withStyles(metricPageStyles)(SetPriceAlertMetric);

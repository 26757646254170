export const alertDetailsTableColumns: any[] = [
  {
    Header: "Stock Ticker",
    accessor: "stockTicker",
    minWidth: 80,
    align: "left"
  },
  {
    Header: "Price Target",
    accessor: "priceTarget",
    minWidth: 80,
    align: "left"
  },
  {
    Header: "Created Date",
    accessor: "activeCreatedDate",
    minWidth: 180,
    align: "left"
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 100,
    align: "left"
  },
  {
    Header: "Hit Date",
    accessor: "hitDate",
    minWidth: 180,
    align: "left"
  }
];

import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getTimestamptString } from "../../utils";

export default function ActiveUsersMChart(props: any) {
  const { series, chartTitle, chartType, populatedDates, maxAmountNumber } = props;
  const options: any = {
    chart: {
      id: chartTitle,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: true
      }
    },
    xaxis: {
      categories: populatedDates,
      labels: {
        formatter: (value: string) => {
          if (value) return getTimestamptString(value, "DD-MM");
        }
      }
    },
    yaxis: {
      title: {
        text: "Number of Users"
      },
      // min: 0,
      // max: maxAmountNumber,
      labels: {
        formatter: function (value: any) {
          if (value === 0) return value;
          if (value) return value.toFixed(0);
        }
      }
    }
  };

  useEffect(() => {
    if (chartTitle && series && series.length) {
      ApexCharts.exec(chartTitle, "updateSeries", series);
    }
  }, [series, chartTitle]);

  if (!series || !chartTitle || !chartType || !populatedDates || !maxAmountNumber)
    return null;

  return (
    <div id="chart">
      <Chart options={options} series={series} type={chartType} />
    </div>
  );
}

export function handleLoginError(error: any): string {
  let msg = "Login Error";
  try {
    if (!error) return "";

    if (error.response && error.response.data) {
      return error.response.data;
    }

    if (error.message) {
      return error.message;
    }

    return msg;
  } catch (error) {
    console.error("[Error] handleLoginError", error);
    return msg;
  }
}

export function handleFetchingError(fetchingError: any) {
  if (!fetchingError) return;
  const errorDetails = {
    apiErrorMessage: "",
    loadingError: "",
    isAuthenticated: false
  };

  try {
    if (!fetchingError.response) {
      errorDetails.loadingError = fetchingError.message;
      errorDetails.isAuthenticated = true;

      return errorDetails;
    }

    if (
      fetchingError.response &&
      fetchingError.response.status &&
      fetchingError.response.data
    ) {
      switch (fetchingError.response.status) {
        case 403:
          errorDetails.apiErrorMessage = "Invalid Session. Please log in again.";
          errorDetails.isAuthenticated = false;
          break;
        default:
          errorDetails.loadingError = fetchingError.response.data;
          errorDetails.isAuthenticated = true;
          break;
      }
    }

    return errorDetails;
  } catch (error) {
    console.error("[Error] handleFetchingError");
    errorDetails.apiErrorMessage = "System Error";
    return errorDetails;
  }
}

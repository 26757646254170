import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
import { fetchConversations } from "../../asyncActions";
import { Message } from "../../components/Message";
import { IMessage } from "../../interfaces";
import { systemUpdateCurrentPath } from "../../actions";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { statisticPagestyles } from "../../layouts/StatisticPage.style";

interface ConversationsProps extends WithStyles<typeof statisticPagestyles> {
  viewData: any;
  system: any;
  location: any;
}

function Conversations(props: ConversationsProps) {
  const dispatch = useDispatch();
  const mainLayoutClasses = mainLayoutStyles();

  const id = props.location.state.id;
  const mobile = props.location.state.mobile;

  const { system, viewData, classes } = props;
  const { isLoading } = viewData;
  const { conversations } = viewData;
  const { accessibleApis } = system;
  const { conversationsEndpoint } = accessibleApis;

  const [isInitialRender, setisInitialRender] = useState(true);

  const defaultStartDate: Date = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate: Date = new Date();
  const defaultStartDateString = moment
    .tz(defaultStartDate, "Asia/Hong_Kong")
    .startOf("day")
    .toISOString();
  const defaultEndDateString = moment
    .tz(defaultEndDate, "Asia/Hong_Kong")
    .endOf("day")
    .toISOString();

  const [selectedStartDate, setselectedStartDate] =
    useState<string>(defaultStartDateString);
  const [selectedEndDate, setselectedEndDate] = useState<string>(defaultEndDateString);

  const csvHeaders = [
    { label: "Date", key: "timestampt" },
    { label: "Message", key: "message" },
    { label: "Sender", key: "sender" },
    { label: "Media URL", key: "mediaUrl" }
  ];

  const handlePickerOnChange = (rangeSelection: any) => {
    try {
      const startDate: Date = rangeSelection.startDate;
      const endDate: Date = rangeSelection.endDate;

      const startDateString = moment
        .tz(startDate, "Asia/Hong_Kong")
        .startOf("day")
        .toISOString();
      const endDateString = moment
        .tz(endDate, "Asia/Hong_Kong")
        .endOf("day")
        .toISOString();
      setselectedStartDate(startDateString);
      setselectedEndDate(endDateString);
    } catch (error) {
      console.error("[Error] Conversations handlePickerOnChange");
    }
  };

  const handleSearch = () => {
    try {
      if (!selectedStartDate || !selectedEndDate) return;

      const conversationsFullEndpoint = `${conversationsEndpoint}/${id}`;
      dispatch(
        fetchConversations(conversationsFullEndpoint, selectedStartDate, selectedEndDate)
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);

      if (!selectedStartDate || !selectedEndDate) return;

      const conversationsFullEndpoint = `${conversationsEndpoint}/${id}`;
      dispatch(
        fetchConversations(conversationsFullEndpoint, selectedStartDate, selectedEndDate)
      );
    }
  }, [
    conversationsEndpoint,
    dispatch,
    id,
    selectedEndDate,
    selectedStartDate,
    isInitialRender
  ]);

  useEffect(() => {
    dispatch(systemUpdateCurrentPath("/users"));
  }, [dispatch]);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Conversations" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Paper className={classes.conversationPaper} id="conversations-container">
          <AppBar position="static" color="default" elevation={0}>
            <Toolbar>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography>{mobile}</Typography>
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                  <CSVLink
                    filename={"conversations.csv"}
                    data={conversations}
                    headers={csvHeaders}
                  >
                    Download CSV
                  </CSVLink>
                </Grid>
              </Grid>
            </Toolbar>
            <Toolbar>
              <Grid container className={classes.dateBar}>
                <Grid item className={classes.dateGrid}>
                  <CustomDateRangePicker handleOnChange={handlePickerOnChange} />
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={handleSearch}>
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          {isLoading && (
            <Typography
              className={classes.conversationInfo}
              color="textSecondary"
              align="center"
            >
              Loading ...
            </Typography>
          )}

          {!isLoading && _.isEmpty(conversations) && (
            <Typography
              className={classes.conversationInfo}
              color="textSecondary"
              align="center"
            >
              No Conversations
            </Typography>
          )}

          {!isLoading && (
            <div id="messages-container" className={classes.messagesContainer}>
              {conversations.map((m: IMessage, i: number) => {
                return <Message key={i} message={m} />;
              })}
            </div>
          )}
        </Paper>
      </main>
    </div>
  );
}

export default withStyles(statisticPagestyles)(Conversations);

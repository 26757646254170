import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter
} from "react-table";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Link from "@material-ui/core/Link";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import AssignmentIcon from "@material-ui/icons/Assignment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

import { userTableStyles } from "./UserTableStyles";
import { userTableColumns } from "./UserTableColumns";
import { RTDefaultColumnFilter, RTGlobalFilter } from "../TableElements";

export function UserTable(props: any) {
  const history = useHistory();
  const classes = userTableStyles();
  const { data } = props;

  const filterTypes = useMemo(
    () => ({
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: RTDefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter, pageIndex, pageSize }
  } = useTable(
    {
      columns: userTableColumns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleMobileOnClick = (clUserId: number, mobileNumber: string): void => {
    history.push({
      pathname: "conversations",
      state: { id: clUserId, mobile: mobileNumber }
    });
  };

  const handleCellRendering = (cell: any, row: any) => {
    try {
      switch (cell.column.id) {
        case "mobileNumber":
          return (
            <>
              <Link
                component="button"
                onClick={() =>
                  handleMobileOnClick(row.values.clUserId, row.values.mobileNumber)
                }
                color="primary"
              >
                <AssignmentIcon />
              </Link>
              <span style={{ marginLeft: "8px" }}>{cell.render("Cell")}</span>
            </>
          );
        default:
          return <>{cell.render("Cell")}</>;
      }
    } catch (error) {
      console.error("[Error] User Table handleCellRendering");
      return <>{cell.render("Cell")}</>;
    }
  };

  const CSVColumnHeaders = userTableColumns.map((u) => {
    return { label: u.Header, key: u.accessor };
  });

  return (
    <Paper className={classes.root} id="data-table-container" elevation={0}>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SearchIcon className={classes.block} color="inherit" />
            </Grid>
            <Grid item xs>
              <RTGlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Grid>
            <Grid item>
              <CSVLink filename={"users.csv"} data={data} headers={CSVColumnHeaders}>
                Download CSV
              </CSVLink>
            </Grid>
          </Grid>
        </Toolbar>
        <Toolbar>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item>
              <Select
                labelId="rows-per-page-select-label"
                id="rows-per-page-select"
                style={{ fontSize: "14px", border: "0px", margin: "0px 20px 0px 0px" }}
                value={pageSize}
                disableUnderline={true}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <MenuItem key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </MenuItem>
                ))}
              </Select>
              <span style={{ fontSize: "14px", margin: "0px 20px 0px 0px" }}>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {<FirstPageIcon />}
              </IconButton>
              <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
                <KeyboardArrowRight />
              </IconButton>
              <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <LastPageIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowUpIcon />
                        )
                      ) : (
                        <UnfoldMoreIcon />
                      )}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {handleCellRendering(cell, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export const pnlRankingTableColumns = [
  {
    accessor: "userid",
    Header: "User ID",
    minWidth: 100,
    align: "left"
  },
  {
    accessor: "rank_date",
    Header: "Date",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "category",
    Header: "Category",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "ranking",
    Header: "Rank",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "added_cash",
    Header: "Cash Injected",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "pnl",
    Header: "Total PNL (HKD)",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "total_value",
    Header: "Total Portfolio Value (HKD)",
    minWidth: 170,
    align: "left"
  }
];

export const dashUserTableColumns = [
  {
    Header: "Profile Name",
    accessor: "profileName",
    width: 100,
    align: "left"
  },
  {
    Header: "Email",
    accessor: "email",
    width: 120,
    align: "left"
  },
  {
    Header: "Company",
    accessor: "company",
    width: 120,
    align: "left"
  }
];

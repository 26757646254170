import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
// import { getTimestamptString, getDaysBetweenDates } from "../../utils";
import { IDefaultRootState } from "../../interfaces";
import { fetchMarketInsightM } from "../../asyncActions";
import { systemUpdateCurrentPath } from "../../actions";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { metricPageStyles } from "../../layouts/MetricPage.style";

interface UsersProps extends WithStyles<typeof metricPageStyles> {
  viewData: any;
  system: any;
}

function MartketInsightMetric(props: UsersProps) {
  const dispatch = useDispatch();
  const { classes } = props;
  const mainLayoutClasses = mainLayoutStyles();

  const system = useSelector((state: IDefaultRootState) => state.system);
  const viewData = useSelector((state: IDefaultRootState) => state.viewData);
  const { accessibleApis } = system;
  const { metricsAnalyticsEndpoint } = accessibleApis;
  // const { isLoading } = viewData;
  // const { MartketInsightMetrics } = viewData;
  // const { data } = MartketInsightMetrics;

  const [isInitialRender, setisInitialRender] = useState(true);

  const defaultStartDate: Date = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate: Date = new Date();
  const defaultStartDateString = moment
    .tz(defaultStartDate, "Asia/Hong_Kong")
    .startOf("day")
    .toISOString();
  const defaultEndDateString = moment
    .tz(defaultEndDate, "Asia/Hong_Kong")
    .endOf("day")
    .toISOString();

  const [selectedStartDate, setselectedStartDate] =
    useState<string>(defaultStartDateString);
  const [selectedEndDate, setselectedEndDate] = useState<string>(defaultEndDateString);
  // const [chartSeries, setchartSeries] = useState(null);

  // const [chartPopulatedDates, setchartPopulatedDates] = useState([]);
  // const [chartMaxAmountNumber, setchartMaxAmountNumber] = useState(0);

  const handleSearch = useCallback(() => {
    try {
      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchMarketInsightM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "detail",
          ["insight_US", "insight_HK"]
        )
      );
    } catch (error) {}
  }, [selectedStartDate, selectedEndDate, dispatch, metricsAnalyticsEndpoint]);

  const handlePickerOnChange = (rangeSelection: any) => {
    try {
      const startDate: Date = rangeSelection.startDate;
      const endDate: Date = rangeSelection.endDate;

      const startDateString = moment
        .tz(startDate, "Asia/Hong_Kong")
        .startOf("day")
        .toISOString();
      const endDateString = moment
        .tz(endDate, "Asia/Hong_Kong")
        .endOf("day")
        .toISOString();
      setselectedStartDate(startDateString);
      setselectedEndDate(endDateString);
    } catch (error) {
      console.error("[Error] marketInsight handlePickerOnChange");
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);

      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchMarketInsightM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "detail",
          ["insight_US", "insight_HK"]
        )
      );
    }
  }, [
    dispatch,
    metricsAnalyticsEndpoint,
    isInitialRender,
    selectedStartDate,
    selectedEndDate
  ]);

  // NOTE: Temp disable chart

  // useEffect(() => {
  //   if (!data) return;
  //   const formattedData = data.map((item: any) => {
  //     item.daily = getTimestamptString(item.daily, "YYYY-MM-DD");
  //     return item;
  //   });

  //   // REF: https://stackoverflow.com/a/10124053/3269542
  //   const ascendingData = formattedData.sort(
  //     (a: any, b: any) => +new Date(a.daily) - +new Date(b.daily)
  //   );

  //   const minDate = ascendingData[0].daily;
  //   const maxDate = ascendingData[ascendingData.length - 1].daily;
  //   const populatedDates: any = getDaysBetweenDates(minDate, maxDate);

  //   const amountLabels = data.map((d: any) => parseInt(d.trade_complete));
  //   const maxAmount: number = _.max(amountLabels) || 1;
  //   const maxAmountNumber = maxAmount * 1.5;

  //   const dataSeries: any = [];
  //   const seriesItems = data.map((d: any) => d.language);
  //   const uniqueItems = _.uniq(seriesItems);
  //   const sortedSeriesItems: any = uniqueItems.sort();

  //   sortedSeriesItems.forEach((seriesItemName: string) => {
  //     const seriesItemArray: any = [];
  //     populatedDates.forEach((date: string) => {
  //       let seriesItemValue: number = 0;
  //       ascendingData.forEach((aData: any) => {
  //         if (aData.language === seriesItemName && aData.daily === date) {
  //           seriesItemValue = parseInt(aData.trade_complete);
  //         }
  //       });
  //       seriesItemArray.push(seriesItemValue);
  //     });
  //     const seriesItem = { name: seriesItemName, data: seriesItemArray };
  //     dataSeries.push(seriesItem);
  //   });

  //   setchartPopulatedDates(populatedDates);
  //   setchartMaxAmountNumber(maxAmountNumber);
  //   setchartSeries(dataSeries);
  // }, [data]);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Market Insight" />

      {/* {!isLoading && _.isEmpty(data) && (
              <div className={classes.contentWrapper}>
                <Typography color="textSecondary" align="center">
                  No Data
                </Typography>
              </div>
            )} */}
      {/* {!isLoading && !_.isEmpty(data) && (
              <div className={classes.contentWrapper}>
                <MetricsChart
                  chartTitle={"example2"}
                  chartType={"line"}
                  series={chartSeries}
                  populatedDates={chartPopulatedDates}
                  maxAmountNumber={chartMaxAmountNumber}
                />
              </div>
            )} */}

      <main id="main-content" className={mainLayoutClasses.main}>
        <Grid container className={classes.dateGridContainer}>
          <Grid item className={classes.dateGridItem}>
            <CustomDateRangePicker handleOnChange={handlePickerOnChange} />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems={"stretch"} spacing={1}>
          {viewData.marketInsightMetrics.length > 0 &&
            viewData.marketInsightMetrics.map((insight: any, i: number) => {
              return (
                <Grid item lg={6} md={6} sm={12} xs={12} key={"grid-item-" + i}>
                  <Paper className={classes.paper}>
                    <Table key={"table-" + i} className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="center">English</TableCell>
                          <TableCell align="center">Cantonese</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                          <TableCell></TableCell>
                          <TableCell align="center">Completed</TableCell>
                          <TableCell align="center">Completed</TableCell>
                        </TableRow>
                        <MartketInsightRowSet
                          nameSearch={insight.insight}
                          insightUS={insight.usMarket}
                          insightHK={insight.hkMarket}
                          title={insight.title}
                        />
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
      </main>
    </div>
  );
}

export default withStyles(metricPageStyles)(MartketInsightMetric);

function MartketInsightRowSet(props: {
  nameSearch: string;
  title: string;
  insightUS: number[];
  insightHK: number[];
}) {
  const { insightUS, insightHK, title, nameSearch } = props;
  return (
    <>
      <TableRow>
        <TableCell align="left" style={{ backgroundColor: "#f4f5f7" }}>
          {title}
        </TableCell>
        {insightUS.map((n, i) => (
          <TableCell align="center" style={{ fontWeight: "bold" }} key={i}>
            {n + insightHK[i]}
          </TableCell>
        ))}
      </TableRow>
      {nameSearch !== "dbs" ? (
        <TableRow>
          <TableCell style={{ backgroundColor: "#f4f5f7" }}>US Stocks</TableCell>
          {insightUS.map((n, i) => (
            <TableCell align="center" key={i}>
              {n}
            </TableCell>
          ))}
        </TableRow>
      ) : (
        <TableRow>
          <TableCell style={{ backgroundColor: "#f4f5f7" }}>US Stocks</TableCell>
          <TableCell align="center">NA</TableCell>
          <TableCell align="center">NA</TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell style={{ backgroundColor: "#f4f5f7" }} align="left">
          HK Stocks
        </TableCell>
        {insightHK.map((n, i) => (
          <TableCell align="center" key={i}>
            {n}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}

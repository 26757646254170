import React from "react";
import TextField from "@material-ui/core/TextField";

// Define a default UI for filtering
export function RTDefaultColumnFilter(props: any) {
  // { column: { filterValue, preFilteredRows, setFilter } }
  const count = props.column.preFilteredRows.length;

  return (
    <TextField
      fullWidth
      style={{
        fontSize: "14px",
        border: "0"
      }}
      placeholder={`Search all in ${count} records...`}
      InputProps={{
        disableUnderline: true
        // className: classes.searchInput
      }}
      value={props.column.filterValue || ""}
      onChange={(e) => {
        props.column.setFilter(e.target.value || undefined);
      }}
    />
  );
}

import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Omit } from "@material-ui/types";

import { systemToggleNavStatus } from "../../actions";
import { IDefaultRootState } from "../../interfaces";
import { DASH_USER_TYPES } from "../../constants";
import { clInternalNav, analyticsNav, statisticsNav, metricsNav } from "./navCategories";

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
      fontFamily: "Nunito",
      fontWeight: "bold"
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover,&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.08)"
      }
    },
    itemCategory: {
      backgroundColor: "#232f3e",
      boxShadow: "0 -1px 0 #404854 inset",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white
    },
    itemActiveItem: {
      color: "#4fc3f7"
    },
    itemPrimary: {
      fontSize: "inherit",
      fontFamily: "Mulish"
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2)
    },
    divider: {
      marginTop: theme.spacing(2)
    }
  });

export interface NavigatorProps
  extends Omit<DrawerProps, "classes">,
    WithStyles<typeof styles> {}

function Navigator(props: NavigatorProps) {
  const dispatch = useDispatch();
  const { classes, ...other } = props;

  const system = useSelector((state: IDefaultRootState) => state.system);
  const { dashUserProfile, activeNavigation, isNavMobileView } = system;
  const [isInitialRender, setisInitialRender] = useState(true);

  const categories: any[] = useMemo(() => [statisticsNav, analyticsNav, metricsNav], []);

  const handleOnDrawerToggle = () => {
    dispatch(systemToggleNavStatus(false));
  };

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);
      if (dashUserProfile) {
        switch (dashUserProfile.company) {
          case DASH_USER_TYPES.CL:
            categories.push(clInternalNav);
            break;
          default:
            break;
        }
      }
    }
  }, [isInitialRender, dashUserProfile, categories]);

  return (
    <Drawer
      variant="permanent"
      open={isNavMobileView}
      onClose={handleOnDrawerToggle}
      {...other}
    >
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          Sandcrawler
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                style={{ fontFamily: "Nunito", fontWeight: "bold" }}
                classes={{ primary: classes.categoryHeaderPrimary }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map((x: any) => {
              const activeClass =
                activeNavigation === x.path ? classes.itemActiveItem : "";
              return (
                <ListItem
                  component={RouterLink}
                  to={x.path}
                  key={x.id}
                  button
                  className={clsx(classes.item, activeClass)}
                >
                  <ListItemIcon className={classes.itemIcon}>{x.icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary
                    }}
                  >
                    {x.id}
                  </ListItemText>
                </ListItem>
              );
            })}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);

export enum EViewDataActions {
  FetchingStarted = "FETCHING_STARTED",
  UsersFetchingCompleted = "USERS_FETCHING_COMPLETED",
  ConversationsFetchingCompleted = "CONVERSATIONS_FETCHING_COMPLETED",
  DashUsersFetchingCompleted = "DASH_USERS_FETCHING_COMPLETED",
  StocksFetchingCompleted = "STOCKS_FETCHING_COMPLETED",
  PopularStocksFetchingCompleted = "POPULAR_STOCKS_FETCHING_COMPLETED",
  AlertsFetchingCompleted = "ALERTS_FETCHING_COMPLETED",
  AlertDetailsFetchingCompleted = "ALERT_DETAILS_FETCHING_COMPLETED",
  TradeNowMFetchingCompleted = "TRADE_NOW_METRICS_FETCHING_COMPLETED",
  MenuTradeMTFetchingCompleted = "MENU_TRADE_METRICS_TABLE_FETCHING_COMPLETED",
  NonMenuTradeMTFetchingCompleted = "NON_MENU_TRADE_METRICS_TABLE_FETCHING_COMPLETED",
  SetPriceAlertMFetchingCompleted = "SET_PRICE_ALERT_METRICS_FETCHING_COMPLETED",
  SetPriceAlertInitMFetchingCompleted = "SET_PRICE_ALERT_INIT_METRICS_FETCHING_COMPLETED",
  ViewPriceAlertMFetchingCompleted = "VIEW_PRICE_ALERT_METRICS_FETCHING_COMPLETED",
  PriceQueryMFetchingCompleted = "PRICE_QUERY_METRICS_FETCHING_COMPLETED",
  MarketInsightMFetchingCompleted = "MARKET_INSIGHT_METRICS_FETCHING_COMPLETED",
  ResetViewData = "RESET_VIEW_DATA",
  MetricsSummaryFetchingCompleted = "METRICS_SUMMARY_FETCHING_COMPLETED",
  OtherMetricsMTFetchingCompleted = "OTHER_METRICS_FETCHING_COMPLETED",
  UsageMetricsMTFetchingCompleted = "USAGE_METRICS_FETCHING_COMPLETED",
  CTAMetricsMTFetchingCompleted = "CTA_METRICS_FETCHING_COMPLETED",
  PNLMTFetchingCompleted = "PNL_FETCHING_COMPLETED",
  SPMTFetchingCompleted = "SP_FETCHING_COMPLETED"
}

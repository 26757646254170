import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

import Header from "../../components/Header";
import { fetchDashUsers } from "../../asyncActions";
import DashUserTable from "../../components/DashUserTable";
import { systemUpdateCurrentPath } from "../../actions";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
import { IDefaultRootState } from "../../interfaces";
import { statisticPagestyles } from "../../layouts/StatisticPage.style";

interface UsersProps extends WithStyles<typeof statisticPagestyles> {}

function DashUsers(props: UsersProps) {
  const dispatch = useDispatch();
  const mainLayoutClasses = mainLayoutStyles();

  const { classes } = props;

  const system = useSelector((state: IDefaultRootState) => state.system);
  const viewData = useSelector((state: IDefaultRootState) => state.viewData);

  const { isLoading } = viewData;

  const [isInitialRender, setisInitialRender] = useState(true);

  const dashUsers = viewData.dashUsers;
  const { accessibleApis } = system;
  const { dashUsersEndpoint } = accessibleApis;

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);
      dispatch(fetchDashUsers(dashUsersEndpoint));
    }
  }, [isInitialRender, dispatch, dashUsersEndpoint]);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Dashboard Users" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Paper className={classes.paper}>
          {isLoading && (
            <div className={classes.contentWrapper}>
              <Fade
                in={isLoading}
                style={{
                  transitionDelay: isLoading ? "800ms" : "0ms"
                }}
                unmountOnExit
              >
                <CircularProgress size={36} />
              </Fade>
              <Typography color="textSecondary" align="center">
                Loading ...
              </Typography>
            </div>
          )}
          {!isLoading && _.isEmpty(dashUsers) && (
            <div className={classes.contentWrapper}>
              <Typography color="textSecondary" align="center">
                No Data
              </Typography>
            </div>
          )}
          {!isLoading && !_.isEmpty(dashUsers) && <DashUserTable data={dashUsers} />}
        </Paper>
      </main>
    </div>
  );
}

export default withStyles(statisticPagestyles)(DashUsers);

import { EViewDataActions } from "../enums";

const initialState = {
  isLoading: false,
  users: [],
  dashUsers: [],
  conversations: [],
  stocks: [],
  popularStocks: {},
  alerts: [],
  alertDetails: [],
  tradeNowMetrics: {},
  menuTradeMetricsTable: {},
  nonMenuTradeMetricsTable: {},
  setPriceAlertMetrics: {},
  setPriceAlertInitMetrics: {},
  viewPriceAlertMetrics: {},
  priceQueryMetrics: {},
  marketInsightMetrics: {},
  metricsSummary: [],
  otherMetrics: {},
  usageMetrics: {},
  ctaMetrics: {},
  pnlRankings: [],
  stockProfitability: []
};

export default function common(state = initialState, action: any) {
  switch (action.type) {
    case EViewDataActions.ResetViewData:
      return {
        ...state,
        isLoading: false,
        users: [],
        dashUsers: [],
        conversations: [],
        stocks: [],
        alerts: [],
        alertDetails: []
      };
    case EViewDataActions.FetchingStarted:
      return {
        ...state,
        isLoading: true
      };
    case EViewDataActions.UsersFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        users: action.payload.data
      };
    case EViewDataActions.ConversationsFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        conversations: action.payload.data
      };
    case EViewDataActions.StocksFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        stocks: action.payload.data
      };
    case EViewDataActions.PopularStocksFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        popularStocks: action.payload.data
      };
    case EViewDataActions.AlertsFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        alerts: action.payload.data
      };
    case EViewDataActions.AlertDetailsFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        alertDetails: action.payload.data
      };
    case EViewDataActions.TradeNowMFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        tradeNowMetrics: action.payload.data
      };
    case EViewDataActions.MenuTradeMTFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        menuTradeMetricsTable: action.payload.data
      };
    case EViewDataActions.NonMenuTradeMTFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        nonMenuTradeMetricsTable: action.payload.data
      };
    case EViewDataActions.SetPriceAlertMFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        setPriceAlertMetrics: action.payload.data
      };
    case EViewDataActions.SetPriceAlertInitMFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        setPriceAlertInitMetrics: action.payload.data
      };
    case EViewDataActions.ViewPriceAlertMFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        viewPriceAlertMetrics: action.payload.data
      };
    case EViewDataActions.PriceQueryMFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        priceQueryMetrics: action.payload.data
      };
    case EViewDataActions.MarketInsightMFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        marketInsightMetrics: action.payload.data
      };
    case EViewDataActions.DashUsersFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        dashUsers: action.payload.data
      };
    case EViewDataActions.MetricsSummaryFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        metricsSummary: action.payload.data
      };
    case EViewDataActions.OtherMetricsMTFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        otherMetrics: action.payload.data
      };
    case EViewDataActions.UsageMetricsMTFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        usageMetrics: action.payload.data
      };
    case EViewDataActions.CTAMetricsMTFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        ctaMetrics: action.payload.data
      };
    case EViewDataActions.PNLMTFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        pnlRankings: action.payload.data
      };
    case EViewDataActions.SPMTFetchingCompleted:
      return {
        ...state,
        isLoading: false,
        stockProfitability: action.payload.data
      };
    default:
      return state;
  }
}

import { connect } from "react-redux";

import Users from "./Users";
import { fetchUsers } from "../../asyncActions";

const mapStateToProps = (state: any) => ({
  system: state.system,
  viewData: state.viewData
});

const mapDispatchToProps = {
  fetchUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

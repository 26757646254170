import { AxiosApiInstance } from "../config/axios.config";
import { loginStartedAction, updateAuthDataAction, loginFailedAction } from "../actions";
import { saveDashUserState } from "../utils";

export const loginDashUser = (path: string, data: any) => async (dispatch: any) => {
  try {
    dispatch(loginStartedAction());
    const res = await AxiosApiInstance.post(path, data);

    if (!res.data || !res.data.dashboard_user) {
      console.error("[Error] loginDashUser no dash user");
    }

    const { dashboard_user } = res.data;
    saveDashUserState(dashboard_user);
    dispatch(updateAuthDataAction(dashboard_user));
  } catch (error) {
    console.error("[Error] loginDashUser failed", error);
    dispatch(loginFailedAction(error));
  }
};

import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import DashboardLayout from "../../layouts/MainLayout";

const AuthenticatedRoute = (props: any) => {
  const location = useLocation();
  const { isAuthenticated } = props;

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: { from: location }
        }}
      />
    );
  }

  return (
    <DashboardLayout>
      <Route {...props} />
    </DashboardLayout>
  );
};

export default AuthenticatedRoute;

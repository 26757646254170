import _ from "lodash";

const getCategoryCounts = (data: any, market: string, category: string) => {
  const cateCounts = {
    enCount: 0,
    zhCount: 0
  };
  try {
    let enCount = 0;
    let zhCount = 0;

    const marketsData: any[] = data[market];
    if (!marketsData || !marketsData.length) {
      console.warn("[Warn] No market data");
      return cateCounts;
    }
    marketsData.forEach((item: any) => {
      if (item.language === "en-gb") {
        enCount += parseInt(item[category]);
      }
      if (item.language === "zh-hk") {
        zhCount += parseInt(item[category]);
      }
    });

    cateCounts.enCount = enCount;
    cateCounts.zhCount = zhCount;

    return cateCounts;
  } catch (error) {
    console.error("[Error] getCategoryCounts", error);
    return cateCounts;
  }
};

export const getMetricsTableData = (initMetricsData: any, completeMetricsData: any) => {
  const metricsSummary = {
    TOTAL: {
      enInitiated: 0,
      zhInitiated: 0,
      enCompleted: 0,
      zhCompleted: 0
    },
    US: {
      enInitiated: 0,
      zhInitiated: 0,
      enCompleted: 0,
      zhCompleted: 0
    },
    HK: {
      enInitiated: 0,
      zhInitiated: 0,
      enCompleted: 0,
      zhCompleted: 0
    }
  };

  try {
    const initCategory = initMetricsData.category;
    const initData = initMetricsData.data;

    const initMarketGroups = _.mapValues(_.groupBy(initData, "market"), (glist) =>
      glist.map((g: any) => _.omit(g, "market"))
    );

    const initUsMarketCounts = getCategoryCounts(initMarketGroups, "US", initCategory);
    metricsSummary.US.enInitiated = initUsMarketCounts.enCount;
    metricsSummary.US.zhInitiated = initUsMarketCounts.zhCount;
    const initHkMarketCounts = getCategoryCounts(initMarketGroups, "HK", initCategory);
    metricsSummary.HK.enInitiated = initHkMarketCounts.enCount;
    metricsSummary.HK.zhInitiated = initHkMarketCounts.zhCount;

    const completeCategory = completeMetricsData.category;
    const completeData = completeMetricsData.data;

    const completeMarketGroups = _.mapValues(_.groupBy(completeData, "market"), (glist) =>
      glist.map((g: any) => _.omit(g, "market"))
    );

    const completeUsMarketCounts = getCategoryCounts(
      completeMarketGroups,
      "US",
      completeCategory
    );
    metricsSummary.US.enCompleted = completeUsMarketCounts.enCount;
    metricsSummary.US.zhCompleted = completeUsMarketCounts.zhCount;
    const completeHkMarketCounts = getCategoryCounts(
      completeMarketGroups,
      "HK",
      completeCategory
    );
    metricsSummary.HK.enCompleted = completeHkMarketCounts.enCount;
    metricsSummary.HK.zhCompleted = completeHkMarketCounts.zhCount;

    metricsSummary.TOTAL.enInitiated =
      metricsSummary.US.enInitiated + metricsSummary.HK.enInitiated;
    metricsSummary.TOTAL.zhInitiated =
      metricsSummary.US.zhInitiated + metricsSummary.HK.zhInitiated;
    metricsSummary.TOTAL.enCompleted =
      metricsSummary.US.enCompleted + metricsSummary.HK.enCompleted;
    metricsSummary.TOTAL.zhCompleted =
      metricsSummary.US.zhCompleted + metricsSummary.HK.zhCompleted;

    return metricsSummary;
  } catch (error) {
    console.error("[Error] getMetricsTableData", error);
    return metricsSummary;
  }
};

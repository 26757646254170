import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";

import Navigator from "../../components/Navigator";
import mainLayoutTheme from "./MainLayout.theme";
import { mainLayoutStyles, drawerWidth } from "./MainLayout.style";

export default function DashboardLayout(props: any) {
  const mainLayoutClasses = mainLayoutStyles();

  return (
    <ThemeProvider theme={mainLayoutTheme}>
      <div className={mainLayoutClasses.root}>
        <CssBaseline />
        <nav className={mainLayoutClasses.drawer} id="nav">
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        {props.children}
      </div>
    </ThemeProvider>
  );
}

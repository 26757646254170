// import _ from "lodash";
// import { ICumulativeMetric } from "../interfaces";

export const getDecimalValues = (value: any) => {
  if (value === 0) return 0;

  if (value === "" || isNaN(value)) return "NA";

  try {
    const floatValue = parseFloat(value);
    const decimalValue = floatValue.toFixed(1);
    return decimalValue;
  } catch (error) {
    return "NA";
  }
};

export const getCategoryCountsByLanguage = (data: any, category: string) => {
  const cateCounts = {
    enCount: 0,
    zhCount: 0
  };
  try {
    let enCount = 0;
    let zhCount = 0;

    data.forEach((item: any) => {
      if (item.language.toLowerCase() === "en-gb") {
        enCount += parseInt(item[category]);
      }
      if (item.language.toLowerCase() === "zh-hk") {
        zhCount += parseInt(item[category]);
      }
    });

    cateCounts.enCount = enCount;
    cateCounts.zhCount = zhCount;

    return cateCounts;
  } catch (error) {
    console.error("[Error] getCategoryCountsByLanguage", error);
    return cateCounts;
  }
};

import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import GetAppIcon from "@material-ui/icons/GetApp";

import { IMessage } from "../../interfaces";
import { markdownText, convertWhatsappToHTML } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 8,
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "flex-start",
      justifyContent: "center"
    },
    paper: {
      margin: 0,
      padding: 8,
      maxWidth: "90%",
      textAlign: "left",
      color: theme.palette.text.secondary
    },
    messageBox: {
      wordBreak: "break-word"
    },
    mediaLink: {
      wordBreak: "break-word"
    },
    imageGrid: {
      margin: "0px 0px 8px 0px",
      padding: 0,
      width: "360px",
      height: "250px"
    },
    messageImage: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "360px",
      height: "250px"
    },
    timeStamp: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
      fontSize: "11px",
      padding: 0,
      margin: "4px 0px 4px 0px",
      color: "#676767"
    }
  })
);

interface MessageProps {
  message: IMessage;
}

export function Message(props: MessageProps) {
  const classes = useStyles();

  const { sender, message, timestampt, mediaUrl } = props.message;

  const msg: string = convertWhatsappToHTML(message);
  const messageSender = sender.toLowerCase();
  let messageAlign = "flex-start";
  let messageBackgroundColor = "";
  let messageTextColor = "";
  if (messageSender === "user") {
    messageAlign = "flex-end";
    messageBackgroundColor = "#DCF8C6";
    messageTextColor = "#000";
  }

  const handleMedia = (media: any): JSX.Element | null => {
    if (!media || !media.length) return null;
    try {
      const mediaUrl: string = media[0];
      if (mediaUrl.endsWith(".pdf")) {
        const mediaArray = mediaUrl.split("/");
        const pdfFileName: string = mediaArray[mediaArray.length - 1];
        return (
          <Grid className={classes.mediaLink}>
            <Link href={mediaUrl} target="_blank" color="primary" underline="none">
              {pdfFileName}
              <GetAppIcon />
            </Link>
          </Grid>
        );
      }

      return (
        <Grid className={classes.imageGrid}>
          <img className={classes.messageImage} src={mediaUrl} alt="" />
        </Grid>
      );
    } catch (error) {
      console.error("[Error] handleMedia");
      return null;
    }
  };
  return (
    <Grid
      id="message-container"
      className={classes.root}
      container
      style={{ alignItems: messageAlign }}
    >
      <Paper
        id="message-content"
        className={classes.paper}
        style={{ backgroundColor: messageBackgroundColor, color: messageTextColor }}
        variant="elevation"
        elevation={4}
      >
        {handleMedia(mediaUrl)}
        <Grid
          item
          id="message-box"
          className={classes.messageBox}
          dangerouslySetInnerHTML={{ __html: markdownText(msg) }}
        />
        <Grid item id="message-timestampt">
          <Typography className={classes.timeStamp} variant="body2" component="span">
            {timestampt}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { fetchAlerts } from "../../asyncActions";
import { AlertTable } from "../../components/AlertTable";
import { systemUpdateCurrentPath } from "../../actions";
import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
import { IDefaultRootState } from "../../interfaces";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { statisticPagestyles } from "../../layouts/StatisticPage.style";

interface AlertsProps extends WithStyles<typeof statisticPagestyles> {
  viewData: any;
  system: any;
}

function Alerts(props: AlertsProps) {
  const dispatch = useDispatch();
  const mainLayoutClasses = mainLayoutStyles();

  const { classes } = props;

  const system = useSelector((state: IDefaultRootState) => state.system);
  const viewData = useSelector((state: IDefaultRootState) => state.viewData);
  const { accessibleApis } = system;
  const { alertsEndpoint, userPriceQueryEndpoing } = accessibleApis;

  const { isLoading } = viewData;
  const { alerts } = viewData;

  const [isInitialRender, setisInitialRender] = useState(true);

  const defaultStartDate: Date = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate: Date = new Date();
  const defaultStartDateString = moment
    .tz(defaultStartDate, "Asia/Hong_Kong")
    .startOf("day")
    .toISOString();
  const defaultEndDateString = moment
    .tz(defaultEndDate, "Asia/Hong_Kong")
    .endOf("day")
    .toISOString();

  const [selectedStartDate, setselectedStartDate] =
    useState<string>(defaultStartDateString);
  const [selectedEndDate, setselectedEndDate] = useState<string>(defaultEndDateString);

  const handlePickerOnChange = (rangeSelection: any) => {
    try {
      const startDate: Date = rangeSelection.startDate;
      const endDate: Date = rangeSelection.endDate;

      const startDateString = moment
        .tz(startDate, "Asia/Hong_Kong")
        .startOf("day")
        .toISOString();
      const endDateString = moment
        .tz(endDate, "Asia/Hong_Kong")
        .endOf("day")
        .toISOString();
      setselectedStartDate(startDateString);
      setselectedEndDate(endDateString);
    } catch (error) {
      console.error("[Error] handlePickerOnChange Alerts");
    }
  };

  const handleSearch = () => {
    try {
      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchAlerts(
          alertsEndpoint,
          userPriceQueryEndpoing,
          selectedStartDate,
          selectedEndDate
        )
      );
    } catch (error) {}
  };

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);

      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchAlerts(
          alertsEndpoint,
          userPriceQueryEndpoing,
          selectedStartDate,
          selectedEndDate
        )
      );
    }
  }, [
    dispatch,
    alertsEndpoint,
    userPriceQueryEndpoing,
    isInitialRender,
    selectedStartDate,
    selectedEndDate
  ]);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="User Activities" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Paper className={classes.paper}>
          <Toolbar>
            <Grid container className={classes.dateBar}>
              <Grid item className={classes.dateGrid}>
                <CustomDateRangePicker handleOnChange={handlePickerOnChange} />
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleSearch}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          {isLoading && (
            <div className={classes.contentWrapper}>
              <Fade
                in={isLoading}
                style={{
                  transitionDelay: isLoading ? "800ms" : "0ms"
                }}
                unmountOnExit
              >
                <CircularProgress size={36} />
              </Fade>
              <Typography color="textSecondary" align="center">
                Loading ...
              </Typography>
            </div>
          )}
          {!isLoading && _.isEmpty(alerts) && (
            <div className={classes.contentWrapper}>
              <Typography color="textSecondary" align="center">
                No Data
              </Typography>
            </div>
          )}
          {!isLoading && !_.isEmpty(alerts) && <AlertTable data={alerts} />}
        </Paper>
      </main>
    </div>
  );
}

export default withStyles(statisticPagestyles)(Alerts);

export const stockTableColumns = [
  {
    accessor: "stockTicker",
    Header: "Stock Ticker",
    minWidth: 160,
    align: "left"
  },
  {
    accessor: "stockFullName",
    Header: "Stock Name",
    minWidth: 160,
    align: "left"
  },
  {
    accessor: "noOfAlerts",
    Header: "No of Alerts",
    minWidth: 80,
    align: "left"
  },
  {
    accessor: "noOfRequests",
    Header: "No of Requests",
    minWidth: 80,
    align: "left"
  },
  {
    accessor: "totalCount",
    Header: "Sum",
    minWidth: 80,
    align: "left"
  }
];

import { ESystemActions } from "../enums";

import {
  loadDashUserState,
  isAuthenticateDashUser,
  handleLoginError,
  handleFetchingError
} from "../utils";

const dashUserState = isAuthenticateDashUser() ? loadDashUserState() : {};

const initialState = {
  isLoading: false,
  isUserLogout: false,
  isAuthenticated: isAuthenticateDashUser(),
  loadingError: "",
  apiErrorMessage: "",
  loginErrorMessage: "",
  dashUserProfile: dashUserState,
  accessiblePaths: {
    signin: "/signin",
    ////// TODO: change temp path
    dashUsersPath: "/dash_users"
  },
  accessibleApis: {
    dashUserLoginEndpoint: "/auth/login",
    dashUsersEndpoint: "/dashboard/dash_users",
    dashUserRegisterEndpoint: "/dashboard/dash_users/register",
    usersEndpoint: "/dashboard/users/users-with-status",
    conversationsEndpoint: "/dashboard/transcripts/account_id",
    alertsEndpoint: "/dashboard/users/alert/stat/user",
    userPriceQueryEndpoing: "/dashboard/analytics/price-query",
    stocksSummaryEndpoint: "/dashboard/users/alert/stat/stock",
    stocksPriceQueryEndpoint: "/dashboard/analytics/price-query-by-stock",
    metricsAnalyticsEndpoint: "/dashboard/analytics/trade",
    pnlRankingsEndpoint: "/dashboard/data/pnl-ranking-dashboard",
    profitableHoldingsEndpoint: "/dashboard/data/profitable-holdings-dashboard"
  },
  activeNavigation: "",
  isNavMobileView: false
};

export default function common(state = initialState, action: any) {
  switch (action.type) {
    case ESystemActions.UpdateCurrentPath:
      const { path } = action.payload;
      return {
        ...state,
        activeNavigation: path
      };
    case ESystemActions.ToggleNavStatus:
      const { navStatus } = action.payload;
      return {
        ...state,
        isNavMobileView: navStatus
      };
    case ESystemActions.UpdateAuthData:
      return {
        ...state,
        dashUserProfile: action.payload,
        isAuthenticated: true,
        loginErrorMessage: ""
      };
    case ESystemActions.ClearAuthData:
      return {
        ...state,
        dashUserProfile: null,
        isAuthenticated: false,
        isLoading: false,
        isUserLogout: true
      };
    case ESystemActions.ValidateAuthData:
      const authencationStatus = isAuthenticateDashUser();
      return {
        ...state,
        isAuthenticated: authencationStatus
      };
    case ESystemActions.LoginFailed:
      const loginErrorMessage = handleLoginError(action.payload);
      return {
        ...state,
        loginErrorMessage: loginErrorMessage
      };
    case ESystemActions.FetchingFailed:
      const errorDetails = handleFetchingError(action.payload);
      return {
        ...state,
        isLoading: false,
        apiErrorMessage: errorDetails?.apiErrorMessage,
        loadingError: errorDetails?.loadingError,
        isAuthenticated: errorDetails?.isAuthenticated
      };
    default:
      return state;
  }
}

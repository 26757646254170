import MarkdownIt from "markdown-it";

const markdownIt = new MarkdownIt({
  html: false, // Enable HTML tags in source
  breaks: true, // Convert '\n' in paragraphs into <br>
  linkify: true, // Autoconvert URL-like text to links
  xhtmlOut: false
});

const defaultRender =
  markdownIt.renderer.rules.link_open ||
  ((tokens, idx, options, env, self) => {
    return self.renderToken(tokens, idx, options);
  });

markdownIt.linkify.set({ fuzzyEmail: false, fuzzyLink: false });

// Configure MarkdownIt to open links in new tab
// DOC: https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
// Remember old renderer, if overriden, or proxy to default renderer
markdownIt.renderer.rules.link_open = (
  tokens: any,
  idx: any,
  options: any,
  env: any,
  self: any
) => {
  // If you are sure other plugins can't add `target` - drop check below
  const targetIndex = tokens[idx].attrIndex("target");

  if (targetIndex < 0) {
    tokens[idx].attrPush(["target", "_blank"]); // add new attribute
  } else {
    tokens[idx].attrs[targetIndex][1] = "_blank"; // replace value of existing attr
  }
  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

export const markdownText = (text: string) => {
  let __html;
  const trimmedText = text.trim();
  __html = markdownIt
    // .enable("newline") // \n to <br>
    // .disable("lheading") // <h2></h2>h2
    // .enable("entity") // e.g &lt; to <
    .disable("list") // e.g ol, li
    // .disable("heading") // <h1>
    .render(trimmedText);

  return __html;
};

export const convertWhatsappToHTML = (msg: string): string => {
  try {
    if (!msg) return "";
    const boldRegex = /\*(.*?)\*/g;
    const strikethroughRegex = /~(.*?)~/g;
    const convertedMsg: string = msg
      .replace(boldRegex, "**$1**")
      .replace(strikethroughRegex, "~~$1~~");

    return convertedMsg;
  } catch (error) {
    console.error("[Error] convertWhatsappToMarkdown", error);
    return msg;
  }
};

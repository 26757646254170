import { IDashUser } from "../interfaces";
import { isExpiredTimestampt } from ".";

export const isAuthenticateDashUser = (): boolean => {
  try {
    const dashUser: any = loadDashUserState();
    // TODO: Add expiry from server and validate timestamp
    // const tokenExpiry = dashUser.tokenExpire;
    // TODO: change token format in Server Side
    if (
      !dashUser ||
      !dashUser.token ||
      !dashUser.token.token ||
      !dashUser.token.tokenExpire
    ) {
      return false;
    }

    const isExpired = isExpiredTimestampt(dashUser.token.tokenExpire);
    return isExpired;
  } catch (error) {
    console.error("[Error] isAuthenticateDashUser", error);
    return false;
  }
};

export const loadDashUserState = () => {
  try {
    const serializedState = localStorage.getItem("dashUserState");
    if (serializedState === null) {
      return undefined;
    }
    const dashUserState: IDashUser = JSON.parse(serializedState);

    return dashUserState;
  } catch (error) {
    console.error("[Error] loadDashUserState", error);
    return undefined;
  }
};

export const saveDashUserState = (state: IDashUser) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("dashUserState", serializedState);
  } catch (error) {
    console.error("[Error] saveDashUserState", error);
  }
};

export const clearDashUserState = () => {
  localStorage.removeItem("dashUserState");
};

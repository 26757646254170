import _ from "lodash";

export const mergeUserAlerts = (
  activeAlerts: any,
  inactiveAlerts: any,
  pricequeries: any
) => {
  try {
    const activeIds = activeAlerts.map((a: any) => a.id);
    const inactiveIds = inactiveAlerts.map((i: any) => i.id);
    const queryIds = pricequeries.map((p: any) => p.userId);
    const alertsIds = activeIds.concat(inactiveIds, queryIds);
    const uniqueAlertsIds = _.uniq(alertsIds);

    const mergedAlerts: any = [];
    uniqueAlertsIds.forEach((a) => {
      if (!a) return;

      let activeCount: number = 0;
      let inactiveCount: number = 0;
      let noOfAlerts: number = 0;
      let noOfPriceQueries: number = 0;
      let mobileNumber: string = "";

      const activeAlert = activeAlerts.find((item: any) => item.id === a);
      const inactiveAlert = inactiveAlerts.find((item: any) => item.id === a);
      const priceQuery = pricequeries.find((item: any) => item.userId === a);

      if (activeAlert !== undefined && activeAlert.active) {
        activeCount = parseInt(activeAlert.active);
      }
      if (inactiveAlert !== undefined && inactiveAlert.inactive) {
        inactiveCount = parseInt(inactiveAlert.inactive);
      }
      if (priceQuery !== undefined && priceQuery.total) {
        noOfPriceQueries = parseInt(priceQuery.total);
      }
      if (activeAlert && activeAlert.socialMediaId)
        mobileNumber = activeAlert.socialMediaId;
      if (inactiveAlert && inactiveAlert.socialMediaId)
        mobileNumber = inactiveAlert.socialMediaId;
      if (priceQuery && priceQuery.socialMediaId) mobileNumber = priceQuery.socialMediaId;

      noOfAlerts = activeCount + inactiveCount;

      const alert = {
        userId: a,
        mobileNumber: mobileNumber,
        noOfAlerts: noOfAlerts,
        noOfPriceQueries: noOfPriceQueries
      };

      mergedAlerts.push(alert);
    });

    return mergedAlerts;
  } catch (error) {
    console.error("[Error] mergeUserAlerts", error);
    return [];
  }
};

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

export default function AuthLayout(props: any) {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {props.children}
    </Container>
  );
}

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 256;

const mainLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh"
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    app: {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    },
    main: {
      flex: 1,
      width: "100%",
      maxWidth: "1008px",
      margin: "4px auto",
      padding: "8px"
    }
  })
);

export { mainLayoutStyles, drawerWidth };

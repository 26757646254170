import { makeStyles } from "@material-ui/core/styles";

export const alertDetailsTableStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "640px"
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  block: {
    display: "block"
  }
});

import { getTimestamptString } from ".";
import { IMessage } from "../interfaces";

export const manipulateConversations = (data: any): IMessage[] => {
  try {
    const conversData: IMessage[] = data.map((c: any) => {
      const message: IMessage = {
        timestampt: getTimestamptString(c.date, "DD/MM/YYYY hh:mm A"),
        message: c.message,
        sender: c.user,
        mediaUrl: c.mediaUrl
      };
      return message;
    });
    return conversData;
  } catch (error) {
    console.error("[Error] manipulateConversations");
    return [];
  }
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { loginDashUser } from "../../asyncActions";
import { IDefaultRootState } from "../../interfaces";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  error: {
    padding: "0px",
    margin: "16px 0px 8px 0px",
    color: "red",
    minHeight: "24px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1)
  }
}));

const formData = {
  email: "",
  password: ""
};

export default function DashUserSignIn() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({ defaultValues: formData });
  const system = useSelector((state: IDefaultRootState) => state.system);
  const { dashUserLoginEndpoint } = system.accessibleApis;
  const { isAuthenticated, loginErrorMessage, isUserLogout } = system;

  const onSubmit = async (data: any) => {
    const formData = {
      dashboard_user: data
    };
    dispatch(loginDashUser(dashUserLoginEndpoint, formData));
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push({ pathname: "/users" });
    }
  });

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Typography className={classes.error}>
        {!isUserLogout && loginErrorMessage}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              className={classes.formControl}
              label="Email"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type="email"
            />
          )}
          rules={{
            required: "Email required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address"
            }
          }}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              fullWidth
              className={classes.formControl}
              label="Password"
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type="password"
            />
          )}
          rules={{ required: "Password required" }}
        />
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </div>
      </form>
    </div>
  );
}

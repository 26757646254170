export const alertTableColumns = [
  {
    Header: "User ID",
    accessor: "userId",
    width: 100,
    align: "left"
  },
  {
    Header: "Mobile",
    accessor: "mobileNumber",
    width: 120,
    align: "left"
  },
  {
    Header: "No of Alerts",
    accessor: "noOfAlerts",
    width: 120,
    align: "left"
  },
  {
    Header: "No of Price Query",
    accessor: "noOfPriceQueries",
    width: 140,
    align: "left"
  }
];

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Typography from "@material-ui/core/Typography";

export const styles = makeStyles({
  root: {
    width: "100%",
    margin: "40px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
});

export const Loading = () => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Fade in={true} unmountOnExit>
        <CircularProgress size={36} />
      </Fade>
      {/* <Typography color="textSecondary" align="center">
        Loading ...
      </Typography> */}
    </div>
  );
};

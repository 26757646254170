import PeopleIcon from "@material-ui/icons/People";

export const statisticsNav = {
  id: "Statistics",
  children: [
    { id: "User Statuses", path: "/users", icon: <PeopleIcon /> },
    {
      id: "User Activities",
      path: "/user-activities",
      icon: (
        <svg
          style={{ height: 20, width: 20 }}
          className="MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
        >
          <path d="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z"></path>
        </svg>
      )
    }
  ]
};

export const analyticsNav = {
  id: "Analytics",
  children: [
    {
      id: "Stocks",
      path: "/stocks",
      icon: (
        <svg
          style={{ height: 20, width: 20 }}
          className="MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
        >
          <path d="M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z"></path>
        </svg>
      )
    }
  ]
};

export const metricsNav = {
  id: "Metric Dashboard",
  children: [
    {
      id: "Overview",
      path: "/",
      icon: null,
      children: []
    },
    { id: "Trade", path: "/trade", icon: null },
    { id: "Price Alert", path: "/price-alert", icon: null },
    {
      id: "Current Stock Price",
      path: "/current-stock-price",
      icon: null
    },
    { id: "Market Insight", path: "/market-insight", icon: null },
    { id: "Others", path: "/others", icon: null }
  ]
};

export const clInternalNav = {
  id: "CL Dashboard",
  children: [
    {
      id: "User Ranking",
      path: "/pnl-rankings",
      icon: null
    },
    {
      id: "Stock Profitability",
      path: "/stock-profitabilities",
      icon: null
    },
    { id: "Dashboard Users", path: "/dash-users", icon: null },
    { id: "Add Dashboard User", path: "/dash-users/register", icon: null }
  ]
};

import axios, { AxiosInstance } from "axios";

import { loadDashUserState } from "../utils";

const serverAPIDomain = process.env.REACT_APP_BOT_SERVER_DOMAIN;

export const AxiosApiInstance: AxiosInstance = axios.create({
  baseURL: serverAPIDomain
});

export const axiosAuthInstanceService = (): AxiosInstance => {
  const dashUser: any = loadDashUserState();
  // TODO: need to change Format in server side later
  const token = dashUser?.token?.token || "";

  const axiosConfigInstance: AxiosInstance = axios.create({
    baseURL: serverAPIDomain,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return axiosConfigInstance;
};

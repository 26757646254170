export enum ESystemActions {
  UpdateCurrentPath = "UPDATE_CURRENT_PATH",
  ToggleNavStatus = "TOGGLE_NAV_STATUS",
  LoginStarted = "LOGIN_STARTED",
  UpdateAuthData = "UPDATE_AUTH_DATA",
  ClearAuthData = "CLEAR_AUTH_DATA",
  LoginFailed = "LOGIN_FAILED",
  ValidateAuthData = "VALIDATE_AUTH_DATA",
  FetchingFailed = "FETCHING_FAILED"
}

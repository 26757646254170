import _ from "lodash";
import { IStock } from "../interfaces";

export const mergeStocks = (summaryData: any, priceQueryData: any) => {
  try {
    const summaryTickers = summaryData.map((s: any) => s.key);
    const priceQueryTickers = priceQueryData.map((q: any) => q.ticker);
    const stockTickers = summaryTickers.concat(priceQueryTickers);
    const uniqueStockTickers: string[] = _.uniq(stockTickers);

    const mergedStocks: any = [];
    uniqueStockTickers.forEach((s: string) => {
      if (!s) return;
      const ticker = s.toLowerCase();

      const summaryStockItem = summaryData.find(
        (item: any) => item.key.toLowerCase() === ticker
      );
      const queryStockItem = priceQueryData.find(
        (item: any) => item.ticker.toLowerCase() === ticker
      );

      const stock: IStock = {
        stockTicker: "",
        stockExchange: "",
        stockFullName: "",
        noOfAlerts: 0,
        noOfRequests: 0,
        totalCount: 0
      };

      if (summaryStockItem && !_.isEmpty(summaryStockItem.key)) {
        stock.stockTicker = summaryStockItem.key;
      }
      if (queryStockItem && !_.isEmpty(queryStockItem.ticker)) {
        stock.stockTicker = queryStockItem.ticker;
      }
      if (summaryStockItem && !_.isEmpty(summaryStockItem.exchange)) {
        stock.stockExchange = summaryStockItem.exchange;
      }
      if (queryStockItem && !_.isEmpty(queryStockItem.exchange)) {
        stock.stockExchange = queryStockItem.exchange;
      }
      if (summaryStockItem && !_.isEmpty(summaryStockItem.full_name)) {
        stock.stockFullName = summaryStockItem.full_name;
      }
      if (queryStockItem && !_.isEmpty(queryStockItem.full_name)) {
        stock.stockFullName = queryStockItem.full_name;
      }
      if (summaryStockItem && !_.isEmpty(summaryStockItem.totalalert)) {
        stock.noOfAlerts = parseInt(summaryStockItem.totalalert);
      }
      if (queryStockItem && !_.isEmpty(queryStockItem.total)) {
        stock.noOfRequests = parseInt(queryStockItem.total);
      }
      stock.totalCount = stock.noOfAlerts + stock.noOfRequests;

      mergedStocks.push(stock);
    });

    return mergedStocks;
  } catch (error) {
    console.error("[Error] mergeUserAlerts", error);
    return [];
  }
};

export const getPopularStocks = (summaryData: any, priceQueryData: any) => {
  try {
    const summaryTickers = summaryData.map((s: any) => s.key);
    const priceQueryTickers = priceQueryData.map((q: any) => q.ticker);
    const stockTickers = summaryTickers.concat(priceQueryTickers);
    const uniqueStockTickers: string[] = _.uniq(stockTickers);

    const mergedStocks: any = [];
    const usStocks: any = [];
    const hkStocks: any = [];

    uniqueStockTickers.forEach((s: string) => {
      if (!s) return;
      const ticker = s.toLowerCase();

      const summaryStockItem = summaryData.find(
        (item: any) => item.key.toLowerCase() === ticker
      );
      const queryStockItem = priceQueryData.find(
        (item: any) => item.ticker.toLowerCase() === ticker
      );

      // if (!summaryStockItem || summaryStockItem === undefined) return;

      const stock = {
        stockTicker: "",
        stockExchange: "",
        stockFullName: "",
        totalCount: 0
      };

      if (summaryStockItem && !_.isEmpty(summaryStockItem.key)) {
        stock.stockTicker = summaryStockItem.key;
      }
      if (queryStockItem && !_.isEmpty(queryStockItem.ticker)) {
        stock.stockTicker = queryStockItem.ticker;
      }
      if (summaryStockItem && !_.isEmpty(summaryStockItem.exchange)) {
        stock.stockExchange = summaryStockItem.exchange;
      }
      if (queryStockItem && !_.isEmpty(queryStockItem.exchange)) {
        stock.stockExchange = queryStockItem.exchange;
      }
      if (summaryStockItem && !_.isEmpty(summaryStockItem.full_name)) {
        stock.stockFullName = summaryStockItem.full_name;
      }
      if (queryStockItem && !_.isEmpty(queryStockItem.full_name)) {
        stock.stockFullName = queryStockItem.full_name;
      }
      let noOfAlerts = 0;
      if (summaryStockItem && !_.isEmpty(summaryStockItem.totalalert)) {
        noOfAlerts = parseInt(summaryStockItem.totalalert);
      }

      let noOfRequests = 0;
      if (queryStockItem && !_.isEmpty(queryStockItem.total)) {
        noOfRequests = parseInt(queryStockItem.total);
      }

      stock.totalCount = noOfAlerts + noOfRequests;

      mergedStocks.push(stock);
    });
    mergedStocks.forEach((item: any) => {
      if (!item.stockExchange) return;
      const exchangeCode = item.stockExchange;
      switch (exchangeCode.toLowerCase()) {
        case "hkex":
          hkStocks.push(item);
          break;
        default:
          usStocks.push(item);
          break;
      }
    });
    const usTopStock = usStocks.sort((a: any, b: any) => b.totalCount - a.totalCount)[0];
    const hkTopStock = hkStocks.sort((a: any, b: any) => b.totalCount - a.totalCount)[0];
    const topStocks = {
      usStock: usTopStock,
      hkStock: hkTopStock
    };
    return topStocks;
  } catch (error) {
    console.error("[Error] getPopularStocks", error);
    return {};
  }
};

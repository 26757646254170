import React, { useState, useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Help } from "@material-ui/icons";

const styles = makeStyles({
  paper: {
    padding: "8px",
    margin: "8px",
    height: "100%"
  },
  titleContainer: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "space-around",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    textAlign: "center",
    marginRight: "8px"
  },
  tooltip: {
    fontSize: "18px",
    verticalAlign: "text-top"
  }
});

const defaultBriefData: any = {
  startDate: "",
  endDate: "",
  enActiveUsers: 0,
  zhActiveUsers: 0,
  usPopularStock: "-",
  hkPopularStock: "-"
};

const OverviewBriefInfo = (props: any) => {
  const classes = styles();

  const { briefInfo } = props;
  const [briefInfoData, setbriefInfoData] = useState(defaultBriefData);

  useEffect(() => {
    if (_.isEmpty(briefInfo)) return;
    setbriefInfoData(briefInfo);
  }, [briefInfo]);

  return (
    <Grid container>
      <Grid item lg md sm={6} xs={12} style={{ marginBottom: "12px" }}>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>English Active Users</Typography>
            {briefInfoData.startDate && briefInfoData.endDate && (
              <Tooltip
                className={classes.tooltip}
                title={`Cumulative number of English active users between ${briefInfoData.startDate} & ${briefInfoData.endDate}`}
                arrow
              >
                <Help></Help>
              </Tooltip>
            )}
          </div>
          <br style={{ clear: "both" }} />
          <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
            {briefInfoData.enActiveUsers && briefInfoData.enActiveUsers}
          </Typography>
        </Paper>
      </Grid>
      <Grid item lg md sm={6} xs={12} style={{ marginBottom: "12px" }}>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Cantonese Active Users</Typography>
            {briefInfoData.startDate && briefInfoData.endDate && (
              <Tooltip
                className={classes.tooltip}
                title={`Cumulative number of Cantonese active users between ${briefInfoData.startDate} & ${briefInfoData.endDate}`}
                arrow
              >
                <Help></Help>
              </Tooltip>
            )}
          </div>
          <br style={{ clear: "both" }} />
          <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
            {briefInfoData.zhActiveUsers && briefInfoData.zhActiveUsers}
          </Typography>
        </Paper>
      </Grid>
      <Grid item lg md sm={6} xs={12} style={{ marginBottom: "12px" }}>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Most Popular US Stock</Typography>
            {briefInfoData.startDate && briefInfoData.endDate && (
              <Tooltip
                className={classes.tooltip}
                title={`Highest sum of price alerts and price query among US stocks between ${briefInfoData.startDate} & ${briefInfoData.endDate}`}
                arrow
              >
                <Help></Help>
              </Tooltip>
            )}
          </div>
          <br style={{ clear: "both" }} />
          <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
            {briefInfoData.usPopularStock && briefInfoData.usPopularStock}
          </Typography>
        </Paper>
      </Grid>
      <Grid item lg md sm={6} xs={12} style={{ marginBottom: "12px" }}>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Most Popular HK Stock</Typography>
            {briefInfoData.startDate && briefInfoData.endDate && (
              <Tooltip
                className={classes.tooltip}
                title={`Highest sum of price alerts and price query among HK stocks between ${briefInfoData.startDate} & ${briefInfoData.endDate}`}
                arrow
              >
                <Help></Help>
              </Tooltip>
            )}
          </div>
          <br style={{ clear: "both" }} />
          <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
            {briefInfoData.hkPopularStock && briefInfoData.hkPopularStock}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OverviewBriefInfo;

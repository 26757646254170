import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import _ from "lodash";

import { DASH_USER_TYPES } from "../../constants";
import DashboardLayout from "../../layouts/MainLayout";

const InternalPrivateRoute = (props: any) => {
  const location = useLocation();
  const isAuthenticated: boolean = props.isAuthenticated;
  const dashUserProfile = props.dashUserProfile;
  let companyName: string = "";
  if (!_.isEmpty(dashUserProfile)) {
    companyName = dashUserProfile.company ? dashUserProfile.company : "";
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: { from: location }
        }}
      />
    );
  }

  if (isAuthenticated && companyName === DASH_USER_TYPES.CL) {
    return (
      <DashboardLayout>
        <Route {...props} />
      </DashboardLayout>
    );
  }

  return (
    <Redirect
      to={{
        pathname: "/page-not-found",
        state: { from: location }
      }}
    />
  );
};

export default InternalPrivateRoute;

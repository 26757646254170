import React from "react";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tooltip } from "@material-ui/core";
import { Help } from "@material-ui/icons";

import { cumulativeTableStyles } from "./CumulativeTable.style";

const CumulativeTable = (props: any) => {
  const classes = cumulativeTableStyles();

  const { data } = props;

  const customCellRendering = (cellCategory: string, cellValue: number) => {
    try {
      if (cellCategory !== "usage_duration" && cellCategory !== "user_calculation") {
        return <TableCell align="center">{cellValue}</TableCell>;
      }
      let cellClassName = "";
      if (isNaN(cellValue)) {
        cellClassName = classes.silverColorText;
      } else if (cellValue > 0) {
        cellClassName = classes.greenColorText;
      } else if (cellValue < 0) {
        cellClassName = classes.redColorText;
      }

      return (
        <TableCell align="center" className={cellClassName}>
          {cellValue}
        </TableCell>
      );
    } catch (error) {
      return <TableCell align="center">{cellValue}</TableCell>;
    }
  };

  return (
    <TableContainer>
      <Table className={classes.table} size={"small"}>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align="left">Categories</TableCell>
            <TableCell align="center">English</TableCell>
            <TableCell align="center">Cantonese</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow key={row[1].categoryName}>
              <TableCell align="left">
                {row[1].categoryName}
                {!_.isEmpty(row[1].tooltipMessage) && (
                  <Tooltip
                    className={classes.tooltipMessage}
                    title={row[1].tooltipMessage}
                    arrow
                  >
                    <Help></Help>
                  </Tooltip>
                )}
              </TableCell>
              {customCellRendering(row[0], row[1].en)}
              {customCellRendering(row[0], row[1].zh)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CumulativeTable;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
// import MetricsChart from "../../components/MetricsChart";
// import { getTimestamptString, getDaysBetweenDates } from "../../utils";
import { IDefaultRootState } from "../../interfaces";
import {
  fetchCTAMetrics,
  // fetchTradeNowM,
  fetchTradeNowMT
} from "../../asyncActions";
import { systemUpdateCurrentPath } from "../../actions";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { metricPageStyles } from "../../layouts/MetricPage.style";

interface UsersProps extends WithStyles<typeof metricPageStyles> {
  viewData: any;
  system: any;
}

function TradeMetric(props: UsersProps) {
  const dispatch = useDispatch();
  const { classes } = props;
  const mainLayoutClasses = mainLayoutStyles();

  const system = useSelector((state: IDefaultRootState) => state.system);
  const viewData = useSelector((state: IDefaultRootState) => state.viewData);
  const { accessibleApis } = system;
  const { metricsAnalyticsEndpoint } = accessibleApis;
  // const { isLoading } = viewData;
  const { menuTradeMetricsTable, nonMenuTradeMetricsTable, ctaMetrics } = viewData;
  // const { tradeNowMetrics } = viewData;
  // const { data } = tradeNowMetrics;

  const [isInitialRender, setisInitialRender] = useState(true);

  const defaultStartDate: Date = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate: Date = new Date();
  const defaultStartDateString = moment
    .tz(defaultStartDate, "Asia/Hong_Kong")
    .startOf("day")
    .toISOString();
  const defaultEndDateString = moment
    .tz(defaultEndDate, "Asia/Hong_Kong")
    .endOf("day")
    .toISOString();

  const [selectedStartDate, setselectedStartDate] =
    useState<string>(defaultStartDateString);
  const [selectedEndDate, setselectedEndDate] = useState<string>(defaultEndDateString);
  // const [chartSeries, setchartSeries] = useState(null);

  // const [chartPopulatedDates, setchartPopulatedDates] = useState([]);
  // const [chartMaxAmountNumber, setchartMaxAmountNumber] = useState(0);

  const handleSearch = () => {
    try {
      if (!selectedStartDate || !selectedEndDate) return;

      // dispatch(
      //   fetchTradeNowM(
      //     metricsAnalyticsEndpoint,
      //     selectedStartDate,
      //     selectedEndDate,
      //     "daily",
      //     "trade_complete"
      //   )
      // );

      dispatch(
        fetchTradeNowMT(metricsAnalyticsEndpoint, selectedStartDate, selectedEndDate)
      );

      dispatch(fetchCTAMetrics(selectedStartDate, selectedEndDate, "detail"));
    } catch (error) {}
  };

  const handlePickerOnChange = (rangeSelection: any) => {
    try {
      const startDate: Date = rangeSelection.startDate;
      const endDate: Date = rangeSelection.endDate;

      const startDateString = moment
        .tz(startDate, "Asia/Hong_Kong")
        .startOf("day")
        .toISOString();
      const endDateString = moment
        .tz(endDate, "Asia/Hong_Kong")
        .endOf("day")
        .toISOString();
      setselectedStartDate(startDateString);
      setselectedEndDate(endDateString);
    } catch (error) {
      console.error("[Error] Trade Metrics handlePickerOnChange");
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);

      if (!selectedStartDate || !selectedEndDate) return;

      // dispatch(
      //   fetchTradeNowM(
      //     metricsAnalyticsEndpoint,
      //     selectedStartDate,
      //     selectedEndDate,
      //     "daily",
      //     "trade_complete"
      //   )
      // );

      dispatch(
        fetchTradeNowMT(metricsAnalyticsEndpoint, selectedStartDate, selectedEndDate)
      );

      dispatch(fetchCTAMetrics(selectedStartDate, selectedEndDate, "detail"));
    }
  }, [
    dispatch,
    metricsAnalyticsEndpoint,
    isInitialRender,
    selectedStartDate,
    selectedEndDate
  ]);

  // NOTE: Temp disable chart

  // useEffect(() => {
  //   if (!data) return;
  //   const formattedData = data.map((item: any) => {
  //     item.daily = getTimestamptString(item.daily, "YYYY-MM-DD");
  //     return item;
  //   });

  //   // REF: https://stackoverflow.com/a/10124053/3269542
  //   const ascendingData = formattedData.sort(
  //     (a: any, b: any) => +new Date(a.daily) - +new Date(b.daily)
  //   );

  //   const minDate = ascendingData[0].daily;
  //   const maxDate = ascendingData[ascendingData.length - 1].daily;
  //   const populatedDates: any = getDaysBetweenDates(minDate, maxDate);

  //   const amountLabels = data.map((d: any) => parseInt(d.trade_complete));
  //   const maxAmount: number = _.max(amountLabels) || 1;
  //   const maxAmountNumber = maxAmount * 1.5;

  //   const dataSeries: any = [];
  //   const seriesItems = data.map((d: any) => d.language);
  //   const uniqueItems = _.uniq(seriesItems);
  //   const sortedSeriesItems: any = uniqueItems.sort();

  //   sortedSeriesItems.forEach((seriesItemName: string) => {
  //     const seriesItemArray: any = [];
  //     populatedDates.forEach((date: string) => {
  //       let seriesItemValue: number = 0;
  //       ascendingData.forEach((aData: any) => {
  //         if (aData.language === seriesItemName && aData.daily === date) {
  //           seriesItemValue = parseInt(aData.trade_complete);
  //         }
  //       });
  //       seriesItemArray.push(seriesItemValue);
  //     });
  //     const seriesItem = { name: seriesItemName, data: seriesItemArray };
  //     dataSeries.push(seriesItem);
  //   });

  //   setchartPopulatedDates(populatedDates);
  //   setchartMaxAmountNumber(maxAmountNumber);
  //   setchartSeries(dataSeries);
  // }, [data]);

  const fromCTAPriceAlert = {
    total: {
      eninit: 0,
      encomplete: 0,
      zhinit: 0,
      zhcomplete: 0
    },
    US: {
      eninit: 0,
      encomplete: 0,
      zhinit: 0,
      zhcomplete: 0
    },
    HK: {
      eninit: 0,
      encomplete: 0,
      zhinit: 0,
      zhcomplete: 0
    }
  };

  const fromCTADBS = {
    eninit: 0,
    encomplete: 0,
    zhinit: 0,
    zhcomplete: 0
  };

  _.forEach(ctaMetrics[0], (v, k) => {
    _.forEach(v, (value) => {
      if (value.language === "en-gb" && value.market === "US")
        fromCTAPriceAlert.US.eninit += Number(value.parameters.pricealert || 0);
      else if (value.language === "en-gb" && value.market === "HK") {
        fromCTAPriceAlert.HK.eninit += Number(value.parameters.pricealert || 0);
        fromCTADBS.eninit += Number(value.parameters.pweb || 0);
      } else if (value.language === "zh-hk" && value.market === "US")
        fromCTAPriceAlert.US.zhinit += Number(value.parameters.pricealert);
      else if (value.language === "zh-hk" && value.market === "HK") {
        fromCTAPriceAlert.HK.zhinit += Number(value.parameters.pricealert || 0);
        fromCTADBS.zhinit += Number(value.parameters.pweb || 0);
      }
    });
  });

  _.forEach(ctaMetrics[1], (v, k) => {
    _.forEach(v, (value) => {
      if (value.language === "en-gb" && value.market === "US")
        fromCTAPriceAlert.US.encomplete += Number(value.parameters.pricealert || 0);
      else if (value.language === "en-gb" && value.market === "HK") {
        fromCTAPriceAlert.HK.encomplete += Number(value.parameters.pricealert || 0);
        fromCTADBS.encomplete += Number(value.parameters.pweb || 0);
      }
      if (value.language === "zh-hk" && value.market === "US")
        fromCTAPriceAlert.US.zhcomplete += Number(value.parameters.pricealert || 0);
      else if (value.language === "zh-hk" && value.market === "HK") {
        fromCTAPriceAlert.HK.zhcomplete += Number(value.parameters.pricealert || 0);
        fromCTADBS.zhcomplete += Number(value.parameters.pweb || 0);
      }
    });
  });

  const tradeByMenu = menuTradeMetricsTable;

  const tradeByNonMenu = nonMenuTradeMetricsTable;

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Trade" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Grid container className={classes.dateGridContainer}>
          <Grid item className={classes.dateGridItem}>
            <CustomDateRangePicker handleOnChange={handlePickerOnChange} />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>

        <Grid container alignItems={"stretch"} spacing={1}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Paper className={classes.paper}>
              {!_.isEmpty(menuTradeMetricsTable) && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithWhiteBackground}`}
                      ></TableCell>
                      <TableCell align="center" colSpan={2}>
                        English
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Cantonese
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      ></TableCell>
                      <TableCell align="center">Initiated</TableCell>
                      <TableCell align="center">Completed</TableCell>
                      <TableCell align="center">Initiated</TableCell>
                      <TableCell align="center">Completed</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        Trade by menu
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {tradeByMenu.TOTAL.enInitiated}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {tradeByMenu.TOTAL.enCompleted}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {tradeByMenu.TOTAL.zhInitiated}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {tradeByMenu.TOTAL.zhCompleted}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        US Stocks
                      </TableCell>
                      <TableCell align="center">{tradeByMenu.US.enInitiated}</TableCell>
                      <TableCell align="center">{tradeByMenu.US.enCompleted}</TableCell>
                      <TableCell align="center">{tradeByMenu.US.zhInitiated}</TableCell>
                      <TableCell align="center">{tradeByMenu.US.zhCompleted}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        HK Stocks
                      </TableCell>
                      <TableCell align="center">{tradeByMenu.HK.enInitiated}</TableCell>
                      <TableCell align="center">{tradeByMenu.HK.enCompleted}</TableCell>
                      <TableCell align="center">{tradeByMenu.HK.zhInitiated}</TableCell>
                      <TableCell align="center">{tradeByMenu.HK.zhCompleted}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Paper className={classes.paper}>
              {!_.isEmpty(nonMenuTradeMetricsTable) && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithWhiteBackground}`}
                      ></TableCell>
                      <TableCell align="center" colSpan={2}>
                        English
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Cantonese
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      ></TableCell>
                      <TableCell align="center">Initiated</TableCell>
                      <TableCell align="center">Completed</TableCell>
                      <TableCell align="center">Initiated</TableCell>
                      <TableCell align="center">Completed</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        From CTA price alert
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {Number(fromCTAPriceAlert.US.eninit) +
                          Number(fromCTAPriceAlert.HK.eninit)}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {Number(fromCTAPriceAlert.US.encomplete) +
                          Number(fromCTAPriceAlert.HK.encomplete)}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {Number(fromCTAPriceAlert.US.zhinit) +
                          Number(fromCTAPriceAlert.HK.zhinit)}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {Number(fromCTAPriceAlert.US.zhcomplete) +
                          Number(fromCTAPriceAlert.HK.zhcomplete)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        US Stocks
                      </TableCell>
                      <TableCell align="center">{fromCTAPriceAlert.US.eninit}</TableCell>
                      <TableCell align="center">
                        {fromCTAPriceAlert.US.encomplete}
                      </TableCell>
                      <TableCell align="center">{fromCTAPriceAlert.US.zhinit}</TableCell>
                      <TableCell align="center">
                        {fromCTAPriceAlert.US.zhcomplete}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        HK Stocks
                      </TableCell>
                      <TableCell align="center">{fromCTAPriceAlert.HK.eninit}</TableCell>
                      <TableCell align="center">
                        {fromCTAPriceAlert.HK.encomplete}
                      </TableCell>
                      <TableCell align="center">{fromCTAPriceAlert.HK.zhinit}</TableCell>
                      <TableCell align="center">
                        {fromCTAPriceAlert.HK.zhcomplete}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Paper className={classes.paper}>
              {!_.isEmpty(nonMenuTradeMetricsTable) && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithWhiteBackground}`}
                      ></TableCell>
                      <TableCell align="center" colSpan={2}>
                        English
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Cantonese
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      ></TableCell>
                      <TableCell align="center">Initiated</TableCell>
                      <TableCell align="center">Completed</TableCell>
                      <TableCell align="center">Initiated</TableCell>
                      <TableCell align="center">Completed</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        Trade by Non Menu
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {tradeByNonMenu.TOTAL.enInitiated}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {tradeByNonMenu.TOTAL.enCompleted}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {tradeByNonMenu.TOTAL.zhInitiated}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {tradeByNonMenu.TOTAL.zhCompleted}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        US Stocks
                      </TableCell>
                      <TableCell align="center">
                        {tradeByNonMenu.US.enInitiated}
                      </TableCell>
                      <TableCell align="center">
                        {tradeByNonMenu.US.enCompleted}
                      </TableCell>
                      <TableCell align="center">
                        {tradeByNonMenu.US.zhInitiated}
                      </TableCell>
                      <TableCell align="center">
                        {tradeByNonMenu.US.zhCompleted}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        HK Stocks
                      </TableCell>
                      <TableCell align="center">
                        {tradeByNonMenu.HK.enInitiated}
                      </TableCell>
                      <TableCell align="center">
                        {tradeByNonMenu.HK.enCompleted}
                      </TableCell>
                      <TableCell align="center">
                        {tradeByNonMenu.HK.zhInitiated}
                      </TableCell>
                      <TableCell align="center">
                        {tradeByNonMenu.HK.zhCompleted}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Paper className={classes.paper}>
              {!_.isEmpty(menuTradeMetricsTable) && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithWhiteBackground}`}
                      ></TableCell>
                      <TableCell align="center" colSpan={2}>
                        English
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Cantonese
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      ></TableCell>
                      <TableCell align="center">Initiated</TableCell>
                      <TableCell align="center">Completed</TableCell>
                      <TableCell align="center">Initiated</TableCell>
                      <TableCell align="center">Completed</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        From CTA DBS research
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {fromCTADBS.eninit}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {fromCTADBS.encomplete}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {fromCTADBS.zhinit}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        {fromCTADBS.zhcomplete}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={`${classes.stickyCell} ${classes.cellWithGrayBackground}`}
                      >
                        HK Stocks
                      </TableCell>
                      <TableCell align="center">{fromCTADBS.eninit}</TableCell>
                      <TableCell align="center">{fromCTADBS.encomplete}</TableCell>
                      <TableCell align="center">{fromCTADBS.zhinit}</TableCell>
                      <TableCell align="center">{fromCTADBS.zhcomplete}</TableCell>
                      {/* <TableCell>{tradeByMenu.HK.enInitiated}</TableCell>
                          <TableCell>{tradeByMenu.HK.enCompleted}</TableCell>
                          <TableCell>{tradeByMenu.HK.zhInitiated}</TableCell>
                          <TableCell>{tradeByMenu.HK.zhCompleted}</TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Grid>
        </Grid>
      </main>

      {/* {!isLoading && _.isEmpty(data) && (
              <div className={classes.contentWrapper}>
                <Typography color="textSecondary" align="center">
                  No Data
                </Typography>
              </div>
            )}
            {!isLoading && !_.isEmpty(data) && (
              <div className={classes.contentWrapper}>
                <MetricsChart
                  chartTitle={"example2"}
                  chartType={"line"}
                  series={chartSeries}
                  populatedDates={chartPopulatedDates}
                  maxAmountNumber={chartMaxAmountNumber}
                />
              </div>
            )} */}
    </div>
  );
}

export default withStyles(metricPageStyles)(TradeMetric);

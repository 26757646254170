export const userTableColumns = [
  {
    accessor: "clUserId",
    Header: "User ID",
    minWidth: 100,
    align: "left"
  },
  {
    accessor: "userName",
    Header: "Name",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "mobileNumber",
    Header: "Mobile",
    minWidth: 140,
    align: "left"
  },
  {
    accessor: "onboardedStatus",
    Header: "Onboard Status",
    minWidth: 170,
    align: "left"
  },
  {
    accessor: "tutorialStatus",
    Header: "Tutorial Status",
    minWidth: 170,
    align: "left"
  }
];

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
import { IDashUser } from "../../interfaces";
import { registerDashUser } from "../../services";
import { systemUpdateCurrentPath } from "../../actions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "0px",
      margin: "auto",
      width: "400px",
      height: "400px",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "space-around",
      alignItems: "flex-end"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    paper: {
      width: "600px",
      margin: "auto",
      overflow: "hidden"
    },
    searchBar: {
      borderBottom: 0
    },
    searchInput: {
      fontSize: theme.typography.fontSize
    },
    block: {
      display: "block"
    },
    contentWrapper: {
      margin: "40px 16px"
    }
  });

interface RegisterProps extends WithStyles<typeof styles> {
  viewData: any;
  system: any;
}

const formData: IDashUser = {
  id: 0,
  profileName: "",
  email: "",
  company: "",
  createdAt: "",
  updatedAt: ""
};

function Register(props: RegisterProps) {
  const dispatch = useDispatch();
  const { classes } = props;
  const mainLayoutClasses = mainLayoutStyles();
  const history = useHistory();
  const { handleSubmit, control } = useForm<IDashUser>({ defaultValues: formData });

  const onSubmit = async (data: any) => {
    const dashboard_user = {
      dashboard_user: data
    };
    const dashUserData = await registerDashUser(
      "/dashboard/dash_users/register",
      dashboard_user
    );
    if (dashUserData) {
      history.push({ pathname: "/dash-users" });
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Dashboard User Register" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Paper className={classes.paper} id="users-container">
          <form
            id="dash-user-form"
            className={classes.root}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="profileName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Profile Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: "Profile name required" }}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Email"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="email"
                />
              )}
              rules={{
                required: "Email required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address"
                }
              }}
            />
            <Controller
              name="company"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={error ? true : false}>
                  <InputLabel id="company-label">Select Company</InputLabel>
                  <Select
                    labelId="company-label"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"CL"}>CL</MenuItem>
                    <MenuItem value={"DBS"}>DBS</MenuItem>
                  </Select>
                  <FormHelperText>{error ? error.message : null}</FormHelperText>
                </FormControl>
              )}
              rules={{ required: "Company required" }}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Password"
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="password"
                />
              )}
              rules={{ required: "Password required" }}
            />
            <div>
              <Button type="submit" variant="contained" color="primary">
                Register
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
}

export default withStyles(styles)(Register);

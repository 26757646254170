import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import Header from "../../components/Header";
import { mainLayoutStyles } from "../../layouts/MainLayout/MainLayout.style";
// import { getTimestamptString, getDaysBetweenDates } from "../../utils";
import { IDefaultRootState } from "../../interfaces";
import { fetchPriceQueryM } from "../../asyncActions";
import { systemUpdateCurrentPath } from "../../actions";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { metricPageStyles } from "../../layouts/MetricPage.style";

interface UsersProps extends WithStyles<typeof metricPageStyles> {
  viewData: any;
  system: any;
}

function PriceQueryMetric(props: UsersProps) {
  const dispatch = useDispatch();
  const { classes } = props;
  const mainLayoutClasses = mainLayoutStyles();

  const system = useSelector((state: IDefaultRootState) => state.system);
  const viewData = useSelector((state: IDefaultRootState) => state.viewData);
  const { accessibleApis } = system;
  const { metricsAnalyticsEndpoint } = accessibleApis;
  // const { isLoading } = viewData;
  const { priceQueryMetrics } = viewData;
  // const { tradeNowMetrics } = viewData;
  // const { data } = tradeNowMetrics;

  const [isInitialRender, setisInitialRender] = useState(true);

  const defaultStartDate: Date = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate: Date = new Date();
  const defaultStartDateString = moment
    .tz(defaultStartDate, "Asia/Hong_Kong")
    .startOf("day")
    .toISOString();
  const defaultEndDateString = moment
    .tz(defaultEndDate, "Asia/Hong_Kong")
    .endOf("day")
    .toISOString();

  const [selectedStartDate, setselectedStartDate] =
    useState<string>(defaultStartDateString);
  const [selectedEndDate, setselectedEndDate] = useState<string>(defaultEndDateString);
  // const [chartSeries, setchartSeries] = useState(null);
  // const [chartPopulatedDates, setchartPopulatedDates] = useState([]);
  // const [chartMaxAmountNumber, setchartMaxAmountNumber] = useState(0);

  const handleSearch = () => {
    try {
      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchPriceQueryM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summay",
          "view_price"
        )
      );
    } catch (error) {}
  };

  const handlePickerOnChange = (rangeSelection: any) => {
    try {
      const startDate: Date = rangeSelection.startDate;
      const endDate: Date = rangeSelection.endDate;

      const startDateString = moment
        .tz(startDate, "Asia/Hong_Kong")
        .startOf("day")
        .toISOString();
      const endDateString = moment
        .tz(endDate, "Asia/Hong_Kong")
        .endOf("day")
        .toISOString();
      setselectedStartDate(startDateString);
      setselectedEndDate(endDateString);
    } catch (error) {
      console.error("[Error] PriceQueryMetric handlePickerOnChange");
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    dispatch(systemUpdateCurrentPath(path));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialRender) {
      setisInitialRender(false);

      if (!selectedStartDate || !selectedEndDate) return;

      dispatch(
        fetchPriceQueryM(
          metricsAnalyticsEndpoint,
          selectedStartDate,
          selectedEndDate,
          "summary",
          "view_price"
        )
      );
    }
  }, [
    dispatch,
    metricsAnalyticsEndpoint,
    isInitialRender,
    selectedStartDate,
    selectedEndDate
  ]);

  // NOTE: Temp disable chart

  // useEffect(() => {
  //   if (!data) return;
  //   const formattedData = data.map((item: any) => {
  //     item.daily = getTimestamptString(item.daily, "YYYY-MM-DD");
  //     return item;
  //   });

  //   // REF: https://stackoverflow.com/a/10124053/3269542
  //   const ascendingData = formattedData.sort(
  //     (a: any, b: any) => +new Date(a.daily) - +new Date(b.daily)
  //   );

  //   const minDate = ascendingData[0].daily;
  //   const maxDate = ascendingData[ascendingData.length - 1].daily;
  //   const populatedDates: any = getDaysBetweenDates(minDate, maxDate);

  //   const amountLabels = data.map((d: any) => parseInt(d.trade_complete));
  //   const maxAmount: number = _.max(amountLabels) || 1;
  //   const maxAmountNumber = maxAmount * 1.5;

  //   const dataSeries: any = [];
  //   const seriesItems = data.map((d: any) => d.language);
  //   const uniqueItems = _.uniq(seriesItems);
  //   const sortedSeriesItems: any = uniqueItems.sort();

  //   sortedSeriesItems.forEach((seriesItemName: string) => {
  //     const seriesItemArray: any = [];
  //     populatedDates.forEach((date: string) => {
  //       let seriesItemValue: number = 0;
  //       ascendingData.forEach((aData: any) => {
  //         if (aData.language === seriesItemName && aData.daily === date) {
  //           seriesItemValue = parseInt(aData.trade_complete);
  //         }
  //       });
  //       seriesItemArray.push(seriesItemValue);
  //     });
  //     const seriesItem = { name: seriesItemName, data: seriesItemArray };
  //     dataSeries.push(seriesItem);
  //   });

  //   setchartPopulatedDates(populatedDates);
  //   setchartMaxAmountNumber(maxAmountNumber);
  //   setchartSeries(dataSeries);
  // }, [data]);

  const priceQuery = {
    Total: {
      enCompleted: 0,
      zhCompleted: 0
    },
    US: {
      enCompleted: 0,
      zhCompleted: 0
    },
    HK: {
      enCompleted: 0,
      zhCompleted: 0
    }
  };

  _.forEach(priceQueryMetrics, (value) => {
    if (value.market === "US") {
      if (value.language === "zh-hk") priceQuery.US.zhCompleted = value.view_price;
      if (value.language === "en-gb") priceQuery.US.enCompleted = value.view_price;
    } else if (value.market === "HK") {
      if (value.language === "zh-hk") priceQuery.HK.zhCompleted = value.view_price;
      if (value.language === "en-gb") priceQuery.HK.enCompleted = value.view_price;
    }
  });

  priceQuery.Total.enCompleted =
    Number(priceQuery.US.enCompleted) + Number(priceQuery.HK.enCompleted);
  priceQuery.Total.zhCompleted =
    Number(priceQuery.US.zhCompleted) + Number(priceQuery.HK.zhCompleted);

  return (
    <div id="main-body" className={mainLayoutClasses.app}>
      <Header pageTitle="Current Stock Price" />
      <main id="main-content" className={mainLayoutClasses.main}>
        <Grid container className={classes.dateGridContainer}>
          <Grid item className={classes.dateGridItem}>
            <CustomDateRangePicker handleOnChange={handlePickerOnChange} />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
        {/* {!isLoading && _.isEmpty(data) && (
              <div className={classes.contentWrapper}>
                <Typography color="textSecondary" align="center">
                  No Data
                </Typography>
              </div>
            )}
            {!isLoading && !_.isEmpty(data) && (
              <div className={classes.contentWrapper}>
                <MetricsChart
                  chartTitle={"example2"}
                  chartType={"line"}
                  series={chartSeries}
                  populatedDates={chartPopulatedDates}
                  maxAmountNumber={chartMaxAmountNumber}
                />
              </div>
            )} */}
        <Grid container alignItems={"stretch"} spacing={1}>
          <Grid item lg md sm xs>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center" colSpan={1}>
                      English
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                      Cantonese
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "#f4f5f7" }}>
                    <TableCell></TableCell>
                    <TableCell align="center">Completed</TableCell>
                    <TableCell align="center">Completed</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellWithGrayBackground}>
                      Show Current Stock Price
                    </TableCell>
                    {Object.entries(priceQuery.Total).map(([key, value]) => (
                      <TableCell key={key} align="center" style={{ fontWeight: "bold" }}>
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellWithGrayBackground}>
                      US Stocks
                    </TableCell>
                    {Object.entries(priceQuery.US).map(([key, value]) => (
                      <TableCell key={key} align="center">
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellWithGrayBackground}>
                      HK Stocks
                    </TableCell>
                    {Object.entries(priceQuery.HK).map(([key, value]) => (
                      <TableCell key={key} align="center">
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

export default withStyles(metricPageStyles)(PriceQueryMetric);

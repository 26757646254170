import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import HomeOutlineIcon from "@material-ui/icons/HomeOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { IDefaultRootState } from "../../interfaces";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  buttonsContainer: {
    width: "100%",
    margin: "42px 0px 0px 0px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "stretch"
  },
  button: {
    margin: "0px 0px 12px 0px"
  },
  buttonIcon: { margin: "0px 8px 0px 0px" },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  error: {
    padding: "0px",
    margin: "16px 0px 8px 0px",
    color: "red",
    minHeight: "24px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1)
  }
}));

export default function DashUserSignIn() {
  const classes = useStyles();
  const history = useHistory();

  const system = useSelector((state: IDefaultRootState) => state.system);

  const { isAuthenticated } = system;

  const goToHome = () => {
    history.push({ pathname: "/users" });
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push({ pathname: "/signin" });
    }
  });

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <ErrorOutlineIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Page not Found
      </Typography>
      <Typography component="h1" variant="h5">
        or
      </Typography>
      <Typography component="h1" variant="h5">
        Not accessable!
      </Typography>
      <div className={classes.buttonsContainer}>
        <Button
          fullWidth
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={goToHome}
        >
          <HomeOutlineIcon className={classes.buttonIcon} /> Go to Home Page
        </Button>
      </div>
    </div>
  );
}

import { ESystemActions } from "../enums";
import { IDashUser } from "../interfaces";

export const systemUpdateCurrentPath = (path: string) => {
  return {
    type: ESystemActions.UpdateCurrentPath,
    payload: { path }
  };
};

export const systemToggleNavStatus = (navStatus: boolean) => {
  return {
    type: ESystemActions.ToggleNavStatus,
    payload: { navStatus }
  };
};

export const loginStartedAction = () => {
  return {
    type: ESystemActions.LoginStarted
  };
};

export const updateAuthDataAction = (dashUserProfile: IDashUser) => {
  return {
    type: ESystemActions.UpdateAuthData,
    payload: dashUserProfile
  };
};

export const clearAuthDataAction = () => {
  return {
    type: ESystemActions.ClearAuthData
  };
};

export const fetchingFailedAction = (error: any) => {
  return {
    type: ESystemActions.FetchingFailed,
    payload: error
  };
};

export const loginFailedAction = (error: any) => {
  return {
    type: ESystemActions.LoginFailed,
    payload: error
  };
};

export const validateAuthDataAction = () => {
  return { type: ESystemActions.ValidateAuthData };
};

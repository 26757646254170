import React, { useState } from "react";
import { TextField, Popover, InputAdornment, IconButton } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { subDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { dateRangePickerStyles } from "./CustomDateRangePicker.style";

interface CustomDateRangePickerProps {
  pickerStartDate?: string;
  pickerEndDate?: string;
  handleOnChange: Function;
}

export default function CustomDateRangePicker(props: CustomDateRangePickerProps) {
  const classes = dateRangePickerStyles();
  const dateFormat = "DD/MM/YYYY";
  const { pickerStartDate, pickerEndDate, handleOnChange } = props;
  const defaultStartDate = pickerStartDate
    ? new Date(pickerStartDate)
    : subDays(new Date(), 7);
  const defaultEndDate = pickerEndDate ? new Date(pickerEndDate) : new Date();

  const [displayCalendar, setdisplayCalendar] = useState(false);
  const [anchorEl, setanchorEl] = useState(null);
  const [inputValue, setinputValue] = useState(
    `${moment(defaultStartDate).format(dateFormat)} - ${moment(defaultEndDate).format(
      dateFormat
    )}`
  );
  const [rangeValues, setrangeValues] = useState([
    {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      key: "selection"
    }
  ]);

  const onAdornmentClick = (e: any) => {
    setdisplayCalendar(true);
    setanchorEl(e.currentTarget);
  };

  const onPopoverClose = (e: any, reason: any) => {
    setdisplayCalendar(false);
    setanchorEl(null);
  };

  const onSelectDateRanges = ({ selection }: any) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate).startOf("day");
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate).startOf("day");
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValue = "";
    if (startDate) inputValue += moment(startDate).format(dateFormat);
    if (endDate) inputValue += " - " + moment(endDate).format(dateFormat);

    const rangeSelection = {
      startDate: startDate,
      endDate: endDate,
      key: "selection"
    };

    setinputValue(inputValue);
    setrangeValues([rangeSelection]);
    handleOnChange(rangeSelection);
  };

  const onInputChange = (e: any) => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);

    const rangeSelection = {
      startDate: fromDate,
      endDate: toDate,
      key: "selection"
    };

    setinputValue(inputValue);
    setrangeValues([rangeSelection]);
    handleOnChange(rangeSelection);
  };

  const processInputValue = (value: any) => {
    let [fromDate, toDate] = value.split("-").map((elm: any) => elm.trim());
    let actualFromDate, actualEndDate;

    fromDate = moment(fromDate, dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    actualFromDate = fromDate;
    actualEndDate = toDate;

    if (moment(fromDate).isAfter(toDate)) {
      actualFromDate = toDate;
      actualEndDate = fromDate;
    }

    return { fromDate: actualFromDate, toDate: actualEndDate };
  };

  return (
    <div>
      <TextField
        label="Start Date - End Date"
        fullWidth={true}
        value={inputValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        onChange={onInputChange}
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={onPopoverClose}
      >
        <div className={classes.calendarWrapper}>
          <DateRangePicker
            ranges={rangeValues}
            onChange={onSelectDateRanges}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            scroll={{ enabled: true }}
            dateDisplayFormat={"dd-MM-yyyy"}
            showDateDisplay={false}
            inputRanges={[]}
          />
        </div>
      </Popover>
    </div>
  );
}

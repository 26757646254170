import { EViewDataActions } from "../enums";

export const viewDataFetchingStartedAction = () => {
  return {
    type: EViewDataActions.FetchingStarted
  };
};

export const usersFetchingCompletedAction = (data: any) => {
  return {
    type: EViewDataActions.UsersFetchingCompleted,
    payload: {
      data
    }
  };
};

export const dashUsersFetchingCompletedAction = (data: any) => {
  return {
    type: EViewDataActions.DashUsersFetchingCompleted,
    payload: {
      data
    }
  };
};

export const conversationsFetchingCompletedAction = (data: any) => {
  return {
    type: EViewDataActions.ConversationsFetchingCompleted,
    payload: {
      data
    }
  };
};

export const stocksFetchingCompletedAction = (data: any) => {
  return {
    type: EViewDataActions.StocksFetchingCompleted,
    payload: {
      data
    }
  };
};

export const popularStocksFCA = (data: any) => {
  return {
    type: EViewDataActions.PopularStocksFetchingCompleted,
    payload: {
      data
    }
  };
};

export const alertsFetchingCompletedAction = (data: any) => {
  return {
    type: EViewDataActions.AlertsFetchingCompleted,
    payload: {
      data
    }
  };
};

export const alertDetailsFetchingCompletedAction = (data: any) => {
  return {
    type: EViewDataActions.AlertDetailsFetchingCompleted,
    payload: {
      data
    }
  };
};

export const tradeNowMFCA = (data: any) => {
  return {
    type: EViewDataActions.TradeNowMFetchingCompleted,
    payload: {
      data
    }
  };
};

export const menuTradeMTFCA = (data: any) => {
  return {
    type: EViewDataActions.MenuTradeMTFetchingCompleted,
    payload: {
      data
    }
  };
};

export const nonMenuTradeMTFCA = (data: any) => {
  return {
    type: EViewDataActions.NonMenuTradeMTFetchingCompleted,
    payload: {
      data
    }
  };
};

export const setPriceAlertMFCA = (data: any) => {
  return {
    type: EViewDataActions.SetPriceAlertMFetchingCompleted,
    payload: {
      data
    }
  };
};

export const setPriceAlertMFCAInit = (data: any) => {
  return {
    type: EViewDataActions.SetPriceAlertInitMFetchingCompleted,
    payload: {
      data
    }
  };
};

export const viewPriceAlertMFCA = (data: any) => {
  return {
    type: EViewDataActions.ViewPriceAlertMFetchingCompleted,
    payload: {
      data
    }
  };
};
export const priceQueryMFCA = (data: any) => {
  return {
    type: EViewDataActions.PriceQueryMFetchingCompleted,
    payload: {
      data
    }
  };
};
export const marketInsightMFCA = (data: any) => {
  return {
    type: EViewDataActions.MarketInsightMFetchingCompleted,
    payload: {
      data
    }
  };
};

export const metricsDataFCA = (data: any) => {
  return {
    type: EViewDataActions.MetricsSummaryFetchingCompleted,
    payload: { data }
  };
};

export const otherMetricDataFCA = (data: any) => {
  return {
    type: EViewDataActions.OtherMetricsMTFetchingCompleted,
    payload: { data }
  };
};

export const usageMetricsDataFCS = (data: any) => {
  return {
    type: EViewDataActions.UsageMetricsMTFetchingCompleted,
    payload: { data }
  };
};

export const ctaMetricsDataFCA = (data: any) => {
  return {
    type: EViewDataActions.CTAMetricsMTFetchingCompleted,
    payload: { data }
  };
};

export const pnlDataFCA = (data: any) => {
  return {
    type: EViewDataActions.PNLMTFetchingCompleted,
    payload: { data }
  };
};

export const stockProfitabilityDataFCA = (data: any) => {
  return {
    type: EViewDataActions.SPMTFetchingCompleted,
    payload: { data }
  };
};

export const resetViewData = () => {
  return {
    type: EViewDataActions.ResetViewData
  };
};
